import Footer from "ui/snippets/footer/Footer";
import TopBar from "ui/snippets/topBar/TopBar";

import Container from "./Container";
import Content from "./Content";
import MainArea from "./MainArea";
import MainColumn from "./MainColumn";
import SideBar from "./SideBar";

export { Container, Content, MainArea, SideBar, MainColumn, Footer, TopBar };

// Container
//    TopBar
//    MainArea
//       SideBar
//       MainColumn
//          Content
//    Footer
