import type { Feature } from "./types";

import { getEnvValue, getChainKeyConfig } from "configs/app/utils";

// const apiEndpoint = getEnvValue("NEXT_PUBLIC_VISUALIZE_API_HOST");
const defaultApi = `${
  getEnvValue("NEXT_PUBLIC_APP_PROTOCOL") || "https"
}://${getEnvValue("NEXT_PUBLIC_APP_HOST")}:${getEnvValue(
  "NEXT_PUBLIC_APP_PORT",
)}`;
const apiEndpoint = defaultApi;

const title = "Solidity to UML diagrams";

const config: Feature<{ api: { endpoint: string; basePath: string } }> =
  (() => {
    if (apiEndpoint) {
      return Object.freeze({
        title,
        isEnabled: true,
        api: {
          endpoint: apiEndpoint,
          basePath: getChainKeyConfig() + "-visualize",
        },
      });
    }

    return Object.freeze({
      title,
      isEnabled: false,
    });
  })();

export default config;
