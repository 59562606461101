import { AddressParam } from "types/api/addressParams";
import { TokenType } from "types/api/token";
import { OSType } from "types/base";
import { NFT_TOKEN_TYPE_IDS, TOKEN_TYPE_IDS } from "./token/tokenTypes";

export const getOSType = (value: string): OSType => {
  if (
    String(value).toLocaleLowerCase() === "cosmos" ||
    String(value).toLocaleLowerCase() === "native_sei"
  )
    return "Cosmos";
  return "EVM";
};

export const getTransactionType = (
  value: string | undefined | null,
): OSType | undefined => {
  if (!value) return undefined;
  if (value.startsWith("0x")) return "EVM";
  return "Cosmos";
};

export const getAddressType = (
  value: string | undefined | null,
): OSType | undefined => {
  if (!value) return undefined;
  if (value.startsWith("0x")) return "EVM";
  if (value.startsWith("sei")) return "Cosmos";
  return undefined;
};

export const revertAddressType = (
  addressType: OSType | undefined,
): OSType | undefined => {
  if (!addressType) return undefined;
  if (addressType === "Cosmos") return "EVM";
  if (addressType === "EVM") return "Cosmos";
  return undefined;
};

export const isSeiAddress = (value: any) => value.toString().startsWith("sei");

export const getIsContract = (address: AddressParam | undefined) => {
  if (!address) return false;
  return address.hash === "0x0000000000000000000000000000000000000000"
    ? false
    : address.is_contract;
};

// const pointerRegex = new RegExp(
//   `(${ALL_TOKEN_TYPE_IDS.map((x) => x.replace("-", "")).join("|")})`,
// );

export type ContractType = false | "original" | "pointer";
export type PointerTypeData = {
  contractType: ContractType;
  associationType: ContractType;
  tokenType: TokenType;
  originHash: string;
  pointerHash: string;
};
export type PointerType = false | PointerTypeData;

// export const getPointerType = (
//   parentHash: string | undefined | null,
//   association: Association | undefined | null,
// ): PointerType => {
//   if (!parentHash || !association) return false;
//   const associationTokenType = association.type
//     .replace("CREATE_", "")
//     .replace("_POINTER", "");
//   const associationTokenNumber = Number(
//     associationTokenType.replace("ERC", "").replace("CW", ""),
//   );

//   if (Number.isNaN(associationTokenNumber)) return false;
//   const isEVMPointer = EVM_TOKEN_TYPES.find(
//     (token) => token.id.replace("-", "") === associationTokenType,
//   );

//   const isNativeSeiPointer = NATIVE_SEI_TOKEN_TYPES.find(
//     (token) => token.id.replace("-", "") === associationTokenType,
//   );

//   if (!isEVMPointer && !isNativeSeiPointer) return false;

//   if (isEVMPointer) {
//     if (parentHash === association.sei_hash) {
//       return {
//         contractType: "original",
//         associationType: "pointer",
//         tokenType: ("ERC-" + associationTokenNumber) as TokenType,
//         originHash: association.sei_hash,
//         pointerHash: association.evm_hash,
//       };
//     }

//     return {
//       contractType: "pointer",
//       associationType: "original",
//       tokenType: ("CW-" + associationTokenNumber) as TokenType,
//       originHash: association.sei_hash,
//       pointerHash: association.evm_hash,
//     };
//   }
//   if (isNativeSeiPointer) {
//     if (parentHash === association.evm_hash)
//       return {
//         contractType: "original",
//         associationType: "pointer",
//         tokenType: ("CW-" + associationTokenNumber) as TokenType,
//         originHash: association.evm_hash,
//         pointerHash: association.sei_hash,
//       };
//     return {
//       contractType: "pointer",
//       associationType: "original",
//       tokenType: ("ERC-" + associationTokenNumber) as TokenType,
//       originHash: association.evm_hash,
//       pointerHash: association.sei_hash,
//     };
//   }
//   return false;
// };

export const getIsNFT = (type: any) => {
  if (NFT_TOKEN_TYPE_IDS.includes(type)) return "nft";
  if (TOKEN_TYPE_IDS.includes(type)) return "token";
  return false;
};

export function isEvmAddressTest(address: string): boolean {
  // EVM addresses start with '0x' and have a length of 42 characters
  const evmPattern = /^0x[a-fA-F0-9]{40}$/;
  return evmPattern.test(address);
}

export function isNativeAddressTest(address: string): boolean {
  // Native addresses start with 'sei' and have a length of 42 or 62 characters
  const seiPattern = /^sei1[0-9a-z]{38}$|^sei1[0-9a-z]{58}$/;
  return seiPattern.test(address);
}

export function isEvmTransactionTest(transaction: string): boolean {
  // EVM transactiones start with '0x' and have a length of 42 characters
  const evmPattern = /^0x[a-fA-F0-9]{64}$/;
  return evmPattern.test(transaction);
}

export function isNativeTransactionTest(transaction: string): boolean {
  // Native transactiones start with 'sei' and have a length of 42 or 62 characters
  const seiPattern = /^(?!0x)[a-fA-F0-9]{64}$/;
  return seiPattern.test(transaction);
}
