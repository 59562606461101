import type React from "react";

import type { Route } from "nextjs-routes";
import { IconName } from "public/icons/name";

type NavIconOrComponent =
  | {
      icon?: IconName;
    }
  | {
      iconComponent?: React.FC<{ size?: number }>;
    };

type NavItemCommon = {
  text: string;
} & NavIconOrComponent;

export type NavItemInternal = NavItemCommon & {
  nextRoute: Route;
  isActive?: boolean;
  disabled?: boolean;
};

export type NavItemExternal = NavItemCommon & {
  url: string;
  isActive?: boolean;
};

export type NavItem = {
  isEvmOnly?: boolean;
  isNewAPI?: boolean;
} & (NavItemInternal | NavItemExternal);

export type NavGroupItem = NavItemCommon & {
  isActive?: boolean;
  subItems: Array<NavItem>; // | Array<Array<NavItem>>;
  subItemsTop?: Array<NavItem>;
};

import type { ArrayElement } from "../utils";

export const NAVIGATION_LINK_IDS = ["rpc_api", "eth_rpc_api"] as const;
export type NavigationLinkId = ArrayElement<typeof NAVIGATION_LINK_IDS>;
