import React from "react";

import getErrorObj from "lib/errors/getErrorObj";

import useProvider from "./useProvider";
import { useCurrentChain } from "lib/hooks/useCurrentChain";

export default function useAddOrSwitchChain() {
  const { wallet, provider } = useProvider();

  const currentChainKey = useCurrentChain();

  return React.useCallback(async () => {
    if (!wallet || !provider || !currentChainKey?.chainId) {
      return;
    }

    const hexadecimalChainId =
      "0x" + Number(currentChainKey?.chainId).toString(16);

    try {
      return await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: hexadecimalChainId }],
      });
    } catch (error) {
      const errorObj = getErrorObj(error);
      const code = errorObj && "code" in errorObj ? errorObj.code : undefined;

      // This error code indicates that the chain has not been added to Wallet.
      if (code === 4902) {
        const params = [
          {
            chainId: hexadecimalChainId,
            chainName: currentChainKey.config.name,
            nativeCurrency: currentChainKey?.config.nativeCurrency,
            rpcUrls: currentChainKey?.config.rpcUrls.default.http,
            blockExplorerUrls: [
              currentChainKey?.config.blockExplorers?.default.url,
            ],
          },
        ] as never;
        console.log(params);
        // in wagmi types for wallet_addEthereumChain method is not provided
        // eslint-disable-next-line @typescript-eslint/no-explicit-any

        return await provider.request({
          method: "wallet_addEthereumChain",
          params: params,
        });
      }

      throw error;
    }
  }, [provider, wallet, currentChainKey?.chainId]);
}
