import { compile } from "path-to-regexp";

import config from "configs/app";

import { getChainKeyConfig } from "configs/app/utils";
import isNeedProxy from "./isNeedProxy";
import type {
  ApiResource,
  ResourceName,
  ResourcePathParams,
} from "./resources";
import { RESOURCES } from "./resources";

export const appendNodeSlud = (resource: string) => {
  const aliasSlugs = ["/api", "/gateway", "/auth", "/workspace"];

  if (!aliasSlugs.filter((slug) => resource.startsWith(slug)).length) {
    return resource;
  }

  const chainKey = getChainKeyConfig();
  return `/${chainKey}${resource}`;
};

export default function buildUrl<R extends ResourceName>(
  resourceName: R,
  pathParams?: ResourcePathParams<R>,
  queryParams?: Record<
    string,
    string | Array<string> | number | null | undefined
  >,
): string {
  const resource: ApiResource = RESOURCES[resourceName];

  const baseUrl = isNeedProxy()
    ? config.app.baseUrl
    : resource.endpoint || config.api.endpoint;

  const basePath =
    resource.basePath !== undefined ? resource.basePath : config.api.basePath;

  const path = isNeedProxy()
    ? "/node-api/proxy" + basePath + resource.path
    : basePath +
      (!resource.basePath?.includes("stats")
        ? appendNodeSlud(resource.path)
        : resource.path);

  const url = new URL(compile(path)(pathParams), baseUrl);

  queryParams &&
    Object.entries(queryParams).forEach(([key, value]) => {
      // there are some pagination params that can be null or false for the next page
      value !== undefined &&
        value !== "" &&
        url.searchParams.append(key, String(value));
    });

  return url.toString();
}
