import { Box, BoxProps } from "@chakra-ui/react";
import LayoutHeader from "./LayoutHeader";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  customContent?: ReactNode;
} & Partial<BoxProps>;

const LayoutDefault = ({ children, customContent, ...props }: Props) => {
  return (
    <LayoutHeader>
      {customContent ? (
        customContent
      ) : (
        <Box
          mx="auto"
          minHeight="full"
          paddingBottom="3.75rem"
          paddingTop={{ base: 4, lg: 6 }}
          px={{ base: 4, lg: 5, xl: 10 }}
          w={{ base: "100%", xl: "1440px" }}
          {...props}
        >
          {children}
        </Box>
      )}
    </LayoutHeader>
  );
};

export default LayoutDefault;
