import config from "configs/app";
import React from "react";
import WalletItem from "./WalletItem";

import useToastModified from "lib/hooks/useToast";
import * as mixpanel from "lib/mixpanel/index";
import useAddOrSwitchChain from "lib/web3/useAddOrSwitchChain";
import useProvider from "lib/web3/useProvider";

const feature = config.features.web3Wallet;

type Props = {
  //
};

const FooterAddToMetamask = ({}: Props) => {
  const toast = useToastModified();
  const { provider, wallet } = useProvider();
  const addOrSwitchChain = useAddOrSwitchChain();

  const handleClickMetamask = React.useCallback(async () => {
    if (!wallet || !provider) {
      return;
    }

    try {
      await addOrSwitchChain();

      toast({
        position: "top-right",
        title: "Success",
        description: "Successfully added network to your wallet",
        status: "success",
        variant: "subtle",
        isClosable: true,
      });

      mixpanel.logEvent(mixpanel.EventTypes.ADD_TO_WALLET, {
        Target: "network",
        Wallet: wallet,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Error",
        description: (error as Error)?.message || "Something went wrong",
        status: "error",
        variant: "subtle",
        isClosable: true,
      });
    }
  }, [addOrSwitchChain, provider, toast, wallet]);

  if (!provider || !wallet || !config.chain.rpcUrl || !feature.isEnabled) {
    return null;
  }
  return (
    <WalletItem
      name="wallets/metamask"
      onClick={handleClickMetamask}
    ></WalletItem>
  );
};

export default FooterAddToMetamask;
