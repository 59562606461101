import config from "configs/app";

import { checkAndConnectFinWallet } from "lib/web3/finwallet";
import WalletItem from "./WalletItem";

const feature = config.features.web3Wallet;

type Props = {
  //
};

const FooterAddToFin = ({}: Props) => {
  if (!config.chain.rpcUrl || !feature.isEnabled) {
    return null;
  }
  return (
    <WalletItem
      src="/images/fin.png"
      onClick={checkAndConnectFinWallet}
    ></WalletItem>
  );
};

export default FooterAddToFin;
