import {
  Box,
  chakra,
  forwardRef,
  HTMLChakraProps,
  Skeleton,
} from "@chakra-ui/react";
import { IconName } from "public/icons/name";
import OptimizationImage, { OptimizationImageProps } from "./OptimizationImage";
import { omit } from "lodash";

export type { IconName };

export const href = "/icons/sprite.svg";

// Define props for component.
export type Props = (
  | ({ src: string } & { name?: never } & HTMLChakraProps<"div">)
  | ({ name: IconName } & { src?: never } & HTMLChakraProps<"div">)
) & {
  alt?: string;
  isLoading?: boolean;
  optimizationImageProps?: Partial<OptimizationImageProps>;
};
export type IconSvgProps = Omit<Props, "src" | "name">;

const IconSvg = (
  { name, src, isLoading, alt, optimizationImageProps, ...props }: Props,
  ref: any,
) => {
  if (isLoading) {
    return (
      <Skeleton
        height="full"
        width="full"
        {...omit(props, "color", "backgroundColor", "background")}
      ></Skeleton>
    );
  }

  return (
    <Box ref={ref} position="relative" height="full" width="full" {...props}>
      {name && (
        <chakra.svg w="100%" h="100%">
          <use href={`${href}#${name}`} />
        </chakra.svg>
      )}
      {src && (
        <OptimizationImage
          alt={alt || src}
          w="100%"
          h="100%"
          src={src}
          {...optimizationImageProps}
        ></OptimizationImage>
      )}
    </Box>
  );
};

// Use forwardRef to wrap the component
export default forwardRef(IconSvg);
