import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, useStore } from "react-redux";

import addressReducer from "./reducers/address";
import chainReducer from "./reducers/chain";
import navigationReducer from "./reducers/navigation";
import overlayReducer from "./reducers/overlay";
import proposalReducer from "./reducers/proposal";

export const store = configureStore({
  reducer: {
    chain: chainReducer,
    address: addressReducer,
    proposal: proposalReducer,
    navigation: navigationReducer,
    overlay: overlayReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  // prepend and concat calls can be chained
});

// Infer the type of store
export type AppStore = typeof store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppStore = useStore.withTypes<AppStore>();
