import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { chainConfigs, ChainKey } from "configs/app/chainConfigs";
import {
  getCookieChainKey,
  setCookieChainKey,
} from "configs/hydration/cookies";
// import * as cookies from "lib/cookies";
import isBrowser from "lib/isBrowser";
import Router from "next/router";

export type ChainState = {
  chainKey: ChainKey | undefined;
};

// const logout = () => {
//   cookies.set(cookies.NAMES.LOGIN_EMAIL, "");
//   cookies.set(cookies.NAMES.ACCESS_TOKEN, "");
//   cookies.set(cookies.NAMES.API_TOKEN, "");
// };

export const chainReducer = createSlice({
  name: "chain",
  initialState: {
    chainKey: (() => {
      if (isBrowser()) {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        const cookieChainKey = getCookieChainKey();
        const chainKey = params.get("chain") || cookieChainKey;

        return chainKey;
      }
      return undefined;
    })(),
  } as ChainState,
  reducers: {
    onChainChange: (
      state,
      data: PayloadAction<{
        chainKey: string | undefined;
        isReload?: boolean;
      }>,
    ) => {
      const { chainKey } = data.payload;
      if (chainKey && chainKey === state.chainKey) {
        setCookieChainKey(chainKey);
        return state;
      }
      const cookieChainKey = getCookieChainKey();

      const matchedChainKey = (chainKey || cookieChainKey) as
        | ChainKey
        | undefined;

      // if (matchedChainKey !== cookieChainKey) {
      //   logout();
      // }

      const chainKeyAvailable = chainConfigs.some(
        (chain) => !chain.disabled && chain.chainKey === matchedChainKey,
      );

      const autoSelectedChainKey = chainKeyAvailable
        ? matchedChainKey
        : (chainConfigs.find(
            (chain) =>
              !chain.disabled &&
              (chain.chainKey === ChainKey.PACIFIC_1 ||
                chain.chainKey === ChainKey.ATLANTIC_2 ||
                chain.chainKey === ChainKey.ARCTIC_1),
          )?.chainKey as ChainKey | undefined);

      setCookieChainKey(autoSelectedChainKey);
      state.chainKey = autoSelectedChainKey;

      if (!Router.query.chain || autoSelectedChainKey !== Router.query.chain) {
        Router.replace(
          {
            query: {
              ...Router.query,
              chain: autoSelectedChainKey || "",
            },
            hash: Router.asPath.split("#")[1],
          },
          undefined,
          { scroll: false },
        ).then(() => {
          if (data.payload.isReload) {
            setTimeout(() => {
              Router.reload();
            }, 100);
          }
        });
      }

      return state;
    },
  },
});

// creators are generated for each case reducer function
export const { onChainChange } = chainReducer.actions;

export default chainReducer.reducer;
