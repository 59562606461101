import config from "configs/app";
import { useAppContext } from "lib/contexts/app";
import * as cookies from "lib/cookies";

export default function useHasAccount() {
  const appProps = useAppContext();

  if (!config.features.account.isEnabled) {
    return false;
  }

  const cookiesString = appProps.cookies;
  const hasAuth = Boolean(cookies.get(cookies.NAMES.API_TOKEN, cookiesString));
  return hasAuth;
}
