import { getFeaturePayload } from "configs/app/features/types";
import type {
  CustomAbis,
  PrivateTag,
  PrivateTagsFilters,
  PrivateTagsResponse,
  PublicTags,
  TokenInfoApplicationConfig,
  TokenInfoApplications,
  UpdateAvatarResponse,
  UserInfo,
  UserQuota,
  VerifiedAddressResponse,
  WatchlistAddress,
  WatchlistEVMResponse,
  WatchlistsFilters,
  WatchlistsResponse,
  WorkspaceUserInfo,
} from "types/api/account";
import type {
  Address,
  AddressBlocksValidatedResponse,
  AddressCoinBalanceHistoryChart,
  AddressCoinBalanceHistoryResponse,
  AddressCollectionsResponse,
  AddressCounters,
  AddressInternalTxsResponse,
  AddressNFTsResponse,
  AddressNFTTokensFilter,
  AddressTabsCounters,
  AddressTokensFilter,
  AddressTokensResponse,
  AddressTokenTransferFilters,
  AddressTokenTransferResponse,
  AddressTransactionsResponse,
  AddressTxsFilters,
  AddressWithdrawalsResponse,
  HoroscopeAddressStatsResponse,
} from "types/api/address";
import type { AddressesResponse } from "types/api/addresses";
import type {
  Block,
  BlockDetailFilter,
  BlockFilters,
  BlocksResponse,
  BlockTransactionsResponse,
  BlockWithdrawalsResponse,
} from "types/api/block";
import type {
  ChartMarketResponse,
  ChartTransactionResponse,
} from "types/api/charts";
import type { BackendVersionConfig } from "types/api/configs";
import type {
  SmartContract,
  SmartContractReadMethod,
  SmartContractVerificationConfig,
  SmartContractWriteMethod,
  SolidityscanReport,
} from "types/api/contract";
import type {
  VerifiedContractsCounters,
  VerifiedContractsFilters,
  VerifiedContractsResponse,
} from "types/api/contracts";
import type { IndexingStatus } from "types/api/indexingStatus";
import type { InternalTransactionsResponse } from "types/api/internalTransaction";
import type { L2DepositsItem, L2DepositsResponse } from "types/api/l2Deposits";
import type { L2OutputRootsResponse } from "types/api/l2OutputRoots";
import type { L2TxnBatchesResponse } from "types/api/l2TxnBatches";
import type { L2WithdrawalsResponse } from "types/api/l2Withdrawals";
import type {
  LogsCosmosResponseAddress,
  LogsResponseAddress,
  LogsResponseTx,
} from "types/api/log";
import type { RawTracesResponse } from "types/api/rawTrace";
import type {
  SearchRedirectResult,
  SearchResult,
  SearchResultFilters,
  SearchResultItem,
} from "types/api/search";
import type {
  Checkpoints,
  Counters,
  HomeStats,
  StatsChart,
  StatsCharts,
} from "types/api/stats";
import type {
  INativeTokenCounters,
  INativeTokenHoldersResponse,
  INativeTokenTransfersResponse,
  TokenCounters,
  TokenHolders,
  TokenICS20sResponse,
  TokenInfo,
  TokenInstance,
  TokenInstanceTransfersCount,
  TokenInventoryFilters,
  TokenInventoryResponse,
  TokenNativesResponse,
  TokenVerifiedInfo,
} from "types/api/token";
import type {
  TokenInstanceTransferResponse,
  TokensBridgedFilters,
  TokensFilters,
  TokensResponse,
  TokensSorting,
} from "types/api/tokens";
import type {
  TokenTransferFilters,
  TokenTransferResponse,
} from "types/api/tokenTransfer";
import type {
  Association,
  Transaction,
  TransactionsResponsePending,
  TransactionsResponseValidated,
  TransactionsResponseWatchlist,
  TransactionsSorting,
} from "types/api/transaction";
import type { TxInterpretationResponse } from "types/api/txInterpretation";
import type { TTxsFilters } from "types/api/txsFilters";
import type { TxStateChanges } from "types/api/txStateChanges";
import type { VerifiedContractsSorting } from "types/api/verifiedContracts";
import type { VisualizedContract } from "types/api/visualization";
import type {
  WithdrawalsCounters,
  WithdrawalsResponse,
} from "types/api/withdrawals";
import type { ZkEvmL2TxnBatchesItem } from "types/api/zkEvmL2TxnBatches";
import type { ArrayElement } from "types/utils";

import config from "configs/app";
import {
  ExchangeCodeFilters,
  ExchangeCodeResponse,
  RegisterFilters,
  RegisterResponse,
  ResetPasswordFilters,
} from "types/api/auth";
import { DefaultFilter } from "types/api/base";
import {
  IABCI,
  IChainStatus,
  IDeposit,
  IDistribution,
  IGenesis,
  IInflation,
  INodeInfo,
  ISlashing,
  IStaking,
  IStakingPool,
  ITallying,
  ITotalSupply,
  IVoting,
} from "types/api/chain";
import {
  IBCChainConnectedResponse,
  IBCChainDetailsResponse,
  IIBCChainConnectedsFilters,
  IIBCChannelTransactionsResponse,
  IIBCRelayerChannel,
  IIBCRelayerChannelTransferAssetsFilters,
  IIBCRelayerChannelTransferAssetsResponse,
  IIBCRelayersStat,
  IIBCRelayerTransferType,
  IIBCTokenCounters,
  IIBCTokenHoldersResponse,
  IIBCTokensResponse,
  IIBCTokenTransfersResponse,
} from "types/api/ibcRelayer";
import {
  PaginatedApiKeyActivityList,
  QueryApiKeyActivityQueryParams,
} from "types/api/pricing-package/api-key-activity.entity";
import {
  ApiKeyPaginatedList,
  ApiKeyRawEntity,
  ApiKeyWithStatsEntity,
  QueryApiKeyParams,
} from "types/api/pricing-package/api-key.entity";
import {
  GetPackageResponse,
  GetPublicPackagesParams,
  GetPublicPackagesResponse,
  IPackagesStats,
  PackageEntity,
  PackagePaginatedQueryParams,
} from "types/api/pricing-package/api-packages.entity";
import {
  ApiResourcePaginatedList,
  ApiResourceQueryParams,
  ApiResourceRawEntity,
} from "types/api/pricing-package/api-resource.entity";
import {
  CustomerStatsEntity,
  UserPackageProfileExtendedEntity,
} from "types/api/pricing-package/user-package-profile.entity";
import {
  GetUserPackageResponse,
  UserPackageEntity,
  UserPackagePaginatedList,
  UserPackagePaginatedQueryParams,
  UserPackageProfilePaginatedList,
  UserPackageProfilePaginatedQueryParams,
  UserPackageSimplifiedEntity,
} from "types/api/pricing-package/user-package.entity";
import {
  ProposalDepositorFilters,
  ProposalDepositorResponse,
  ProposalDetailFilters,
  ProposalDetailsResponse,
  ProposalDetailStatsResponse,
  ProposalFilters,
  ProposalGovernanceTallyingResponse,
  ProposalResponse,
  ProposalStats,
  ProposalValidatorVoteFilters,
  ProposalValidatorVoteResponse,
  ProposalVoteFilters,
  ProposalVoteResponse,
} from "types/api/proposal";
import { GlobalPublicTagsResponse } from "types/api/tags";
import {
  ValidatorDetailFilter,
  ValidatorDetailResponse,
  ValidatorInfosResponse,
  ValidatorsFilter,
  ValidatorsStatsResponse,
} from "types/api/validator";
import { OSType } from "types/base";

export interface ApiResource {
  path: ResourcePath;
  endpoint?: string;
  basePath?: string;
  pathParams?: Array<string>;
  needAuth?: boolean; // for external APIs which require authentication
  needWorkspaceAuth?: boolean;
}

export const SORTING_FIELDS = ["sort", "order"];

export const RESOURCES = {
  // commont
  checkpoints: {
    path: "/gateway/api/v1/checkpoints",
  },
  // Workspace account apis
  workspace_userinfo: {
    path: "/workspace/api/v1/user/profile",
    needWorkspaceAuth: true,
  },
  workspace_update_userinfo: {
    path: "/workspace/api/v1/user/profile",
    needWorkspaceAuth: true,
  },
  workspace_update_avatar: {
    path: "/workspace/api/v1/user/avatar",
    needWorkspaceAuth: true,
  },
  workspace_change_password: {
    path: "/workspace/api/v1/user/password",
    needWorkspaceAuth: true,
  },
  issue_workspace_token: {
    path: "/auth/issue-workspace-token",
  },
  my_quota: {
    path: "/workspace/api/v1/user-quota/me",
    needWorkspaceAuth: true,
  },
  api_package_detail: {
    path: "/workspace/api/v1/package/:id",
    pathParams: ["id" as const],
    needWorkspaceAuth: false,
  },
  active_free_trial_package: {
    path: "/workspace/api/v1/user-package/activate-free-trial-package",
    needWorkspaceAuth: true,
  },

  // ADMIN ROLE
  admin_get_user_package_profile_stats: {
    path: "/workspace/api/v1/admin/user-package-profile/stats",
    needWorkspaceAuth: true,
  },
  admin_create_assign_user_package: {
    path: "/workspace/api/v1/admin/user-package/:userId",
    pathParams: ["userId" as const],
    needWorkspaceAuth: true,
  },
  admin_get_user_package_list: {
    path: "/workspace/api/v1/admin/user-package",
    needWorkspaceAuth: true,
  },
  admin_get_specific_user_package_list: {
    path: "/workspace/api/v1/admin/user-package/:userId",
    pathParams: ["userId" as const],
    needWorkspaceAuth: true,
  },
  user_package: {
    path: "/workspace/api/v1/user-package",
    needWorkspaceAuth: true,
  },
  latest_user_package: {
    path: "/workspace/api/v1/admin/user-package/:userId/latest",
    pathParams: ["userId" as const],
    needWorkspaceAuth: true,
  },
  update_user_package: {
    path: "/workspace/api/v1/admin/user-package/:userId/:userPackageId",
    pathParams: ["userId" as const, "userPackageId" as const],
    needWorkspaceAuth: true,
  },
  api_package: {
    path: "/workspace/api/v1/admin/package",
    needWorkspaceAuth: true,
  },
  create_api_package: {
    path: "/workspace/api/v1/admin/package",
    needWorkspaceAuth: true,
  },
  update_api_package: {
    path: "/workspace/api/v1/admin/package/:id",
    pathParams: ["id" as const],
    needWorkspaceAuth: true,
  },
  user_package_profile_list: {
    path: "/workspace/api/v1/admin/user-package-profile",
    filterFields: [
      "package_id_list" as const,
      "is_regular_account" as const,
      "limit" as const,
      "offset" as const,
      "search_terms" as const,
    ],
    needWorkspaceAuth: true,
  },
  user_package_profile: {
    path: "/workspace/api/v1/admin/user-package-profile/:id",
    pathParams: ["id" as const],
    needWorkspaceAuth: true,
  },
  user_package_profile_action: {
    path: "/workspace/api/v1/admin/user-package-profile/:id/:action",
    pathParams: ["id" as const, "action" as const],
    needWorkspaceAuth: true,
  },
  get_api_resource_list: {
    path: "/workspace/api/v1/api-resource",
    needWorkspaceAuth: true,
  },
  add_api_resource: {
    path: "/workspace/api/v1/admin/api-resource",
    needWorkspaceAuth: true,
  },
  update_api_resource: {
    path: "/workspace/api/v1/admin/api-resource/:id",
    pathParams: ["id" as const],
    needWorkspaceAuth: true,
  },
  delete_api_resource: {
    path: "/workspace/api/v1/admin/api-resource/:id",
    pathParams: ["id" as const],
    needWorkspaceAuth: true,
  },
  packages_stats: {
    path: "/workspace/api/v1/admin/user-package/stats",
    needWorkspaceAuth: true,
  },

  // USER ROLE
  user_update_user_package_action: {
    path: "/workspace/api/v1/user-package/:userPackageId/:action",
    pathParams: ["userPackageId" as const, "action" as const],
    needWorkspaceAuth: true,
  },
  public_packages: {
    path: "/workspace/api/v1/package/public-packages",
  },
  package_info: {
    path: "/workspace/api/v1/package/:id",
    pathParams: ["id" as const],
  },
  user_package_register: {
    path: "/workspace/api/v1/user-package",
    needWorkspaceAuth: true,
  },
  get_user_package_profile: {
    path: "/workspace/api/v1/user-package/profile",
    needWorkspaceAuth: true,
  },
  active_package: {
    path: "/workspace/api/v1/user-package/active-package",
    needWorkspaceAuth: true,
  },
  user_get_latest_user_package: {
    path: "/workspace/api/v1/user-package/latest-package",
    needWorkspaceAuth: true,
  },
  get_api_key_list: {
    path: "/workspace/api/v1/api-keys",
    needWorkspaceAuth: true,
  },
  get_api_key: {
    path: "/workspace/api/v1/api-keys/:id",
    pathParams: ["id" as const],
    needWorkspaceAuth: true,
  },
  delete_api_key: {
    path: "/workspace/api/v1/api-keys/:id",
    pathParams: ["id" as const],
    needWorkspaceAuth: true,
  },
  create_api_key: {
    path: "/workspace/api/v1/api-keys",
    needWorkspaceAuth: true,
  },
  update_api_key: {
    path: "/workspace/api/v1/api-keys/:id",
    pathParams: ["id" as const],
    needWorkspaceAuth: true,
  },
  get_api_key_activities: {
    path: "/workspace/api/v1/api-keys/activities",
    needWorkspaceAuth: true,
  },

  // ACCOUNT
  csrf: {
    path: "/api/account/v2/get_csrf",
  },
  user_info: {
    path: "/api/account/v2/user/info",
  },
  update_userinfo: {
    path: "/gateway/api/v1/auth/profile/update-userinfo",
  },
  update_avatar: {
    path: "/gateway/api/v1/auth/profile/update-avatar",
  },
  change_password: {
    path: "/gateway/api/v1/auth/change-password",
  },
  email_resend: {
    path: "/api/account/v2/email/resend",
  },
  custom_abi: {
    path: "/api/account/v2/user/custom_abis/:id?",
    pathParams: ["id" as const],
  },
  watchlist_evm: {
    path: "/api/account/v2/user/watchlist/:id?",
    pathParams: ["id" as const],
    filterFields: [],
    needAuth: true,
  },
  watchlist_native: {
    path: "/workspace/api/v1/watchlist/:id?",
    pathParams: ["id" as const],
    filterFields: [],
    needWorkspaceAuth: true,
  },
  watchlist_byaddress: {
    path: "/workspace/api/v1/watchlist/address/:hash",
    pathParams: ["hash" as const],
    filterFields: ["chain_id" as const],
    needWorkspaceAuth: true,
  },
  private_tag_byhash: {
    path: "/workspace/api/v1/private-tag/hash/:hash",
    pathParams: ["hash" as const],
    filterFields: ["chain_id" as const, "type" as const],
    needWorkspaceAuth: true,
  },
  watchlists: {
    path: "/workspace/api/v1/watchlist",
    filterFields: [
      "chain_id" as const,
      "search_terms" as const,
      "address_type" as const,
      "limit" as const,
      "offset" as const,
    ],
    needWorkspaceAuth: true,
  },
  public_tags: {
    path: "/api/account/v2/user/public_tags/:id?",
    pathParams: ["id" as const],
  },
  private_tags: {
    path: "/workspace/api/v1/private-tag",
    filterFields: [
      "chain_id" as const,
      "search_terms" as const,
      "type" as const,
      "limit" as const,
      "offset" as const,
      "address_type" as const,
    ],
    needWorkspaceAuth: true,
  },
  private_tag: {
    path: "/workspace/api/v1/private-tag/:id?",
    pathParams: ["id" as const],
    needWorkspaceAuth: true,
  },

  // VALIDATOR
  validators: {
    path: "/gateway/api/v1/validators",
    filterFields: ["status" as const, "q" as const],
  },
  validator: {
    path: "/gateway/api/v1/validators/:address",
    pathParams: ["address" as const],
  },
  validators_stats: {
    path: "/gateway/api/v1/validators/stats",
  },

  // ACCOUNT: ADDRESS VERIFICATION & TOKEN INFO
  address_verification: {
    path: "/api/v1/chains/:chain/user-packageId/verified-addresses:type",
    pathParams: ["chainId" as const, "type" as const],
    endpoint: getFeaturePayload(config.features.verifiedTokens)?.api.endpoint,
    basePath: getFeaturePayload(config.features.verifiedTokens)?.api.basePath,
    needAuth: true,
  },

  verified_addresses: {
    path: "/api/v1/chains/:chainId/verified-addresses",
    pathParams: ["chainId" as const],
    endpoint: getFeaturePayload(config.features.verifiedTokens)?.api.endpoint,
    basePath: getFeaturePayload(config.features.verifiedTokens)?.api.basePath,
    needAuth: true,
  },

  token_info_applications_config: {
    path: "/api/v1/chains/:chainId/token-info-submissions/selectors",
    pathParams: ["chainId" as const],
    endpoint: getFeaturePayload(config.features.addressVerification)?.api
      .endpoint,
    basePath: getFeaturePayload(config.features.addressVerification)?.api
      .basePath,
    needAuth: true,
  },

  token_info_applications: {
    path: "/api/v1/chains/:chainId/token-info-submissions/:id?",
    pathParams: ["chainId" as const, "id" as const],
    endpoint: getFeaturePayload(config.features.addressVerification)?.api
      .endpoint,
    basePath: getFeaturePayload(config.features.addressVerification)?.api
      .basePath,
    needAuth: true,
  },

  // STATS
  stats_counters: {
    path: "/api/v1/counters",
    endpoint: getFeaturePayload(config.features.stats)?.api.endpoint,
    basePath: getFeaturePayload(config.features.stats)?.api.basePath,
  },
  stats_lines: {
    path: "/api/v1/lines",
    endpoint: getFeaturePayload(config.features.stats)?.api.endpoint,
    basePath: getFeaturePayload(config.features.stats)?.api.basePath,
  },
  stats_line: {
    path: "/api/v1/lines/:id",
    pathParams: ["id" as const],
    endpoint: getFeaturePayload(config.features.stats)?.api.endpoint,
    basePath: getFeaturePayload(config.features.stats)?.api.basePath,
  },

  // VISUALIZATION
  visualize_sol2uml: {
    path: "/api/v1/solidity\\:visualize-contracts",
    endpoint: getFeaturePayload(config.features.sol2uml)?.api.endpoint,
    basePath: getFeaturePayload(config.features.sol2uml)?.api.basePath,
  },

  // AUTH
  request_challenge: {
    path: "/gateway/api/v1/auth/challenge/:target",
    pathParams: ["target" as const],
  },
  reset_password: {
    path: "/gateway/api/v1/auth/reset-password",
    filterFields: ["email" as const, "password" as const, "otp" as const],
  },
  register: {
    path: "/gateway/api/v1/auth/register",
    filterFields: ["email" as const, "password" as const],
  },
  exchange_code: {
    path: "/gateway/api/v1/auth/exchange-code",
    filterFields: ["code" as const, "email" as const, "password" as const],
  },

  // BLOCKS, TXS
  blocks: {
    path: "/gateway/api/v1/blocks",
    filterFields: ["type" as const, "limit" as const],
  },
  block: {
    path: "/gateway/api/v1/blocks/:height_or_hash",
    pathParams: ["height_or_hash" as const],
  },
  block_txs: {
    path: "/gateway/api/v1/blocks/:height_or_hash/transactions",
    pathParams: ["height_or_hash" as const],
    filterFields: ["type" as OSType],
  },
  block_withdrawals: {
    path: "/api/v2/blocks/:height_or_hash/withdrawals",
    pathParams: ["height_or_hash" as const],
    filterFields: [],
  },
  latest_txs_validated: {
    // path: "/api/v2/transactions",
    path: "/gateway/api/v1/transactions/latest",
    filterFields: [
      "filters" as const,
      "type" as const,
      "method" as const,
      "limit" as const,
    ],
  },
  txs_validated: {
    // path: "/api/v2/transactions",
    path: "/gateway/api/v1/transactions",
    filterFields: [
      "filters" as const,
      "type" as const,
      "method" as const,
      "limit" as const,
    ],
  },
  txs_pending: {
    path: "/api/v2/transactions",
    filterFields: ["filters" as const, "type" as const, "method" as const],
  },
  txs_watchlist: {
    path: "/api/v2/transactions/watchlist",
    filterFields: [],
  },
  txs_execution_node: {
    path: "/api/v2/transactions/execution-node/:hash",
    pathParams: ["hash" as const],
    filterFields: [],
  },
  tx: {
    path: "/gateway/api/v1/transactions/:hash",
    pathParams: ["hash" as const],
  },
  tx_internal_txs: {
    path: "/api/v2/transactions/:hash/internal-transactions",
    pathParams: ["hash" as const],
    filterFields: [],
  },

  tx_logs: {
    path: "/gateway/api/v1/transactions/:hash/logs",
    pathParams: ["hash" as const],
    filterFields: [],
  },
  tx_token_transfers: {
    path: "/gateway/api/v1/transactions/:hash/token-transfers",
    pathParams: ["hash" as const],
    filterFields: ["type" as const],
  },
  tx_raw_trace: {
    path: "/api/v2/transactions/:hash/raw-trace",
    pathParams: ["hash" as const],
  },
  tx_state_changes: {
    path: "/api/v2/transactions/:hash/state-changes",
    pathParams: ["hash" as const],
    filterFields: [],
  },
  tx_interpretation: {
    path: "/api/v2/transactions/:hash/summary",
    pathParams: ["hash" as const],
  },
  withdrawals: {
    path: "/api/v2/withdrawals",
    filterFields: [],
  },
  withdrawals_counters: {
    path: "/api/v2/withdrawals/counters",
  },

  // ADDRESSES
  addresses: {
    path: "/gateway/api/v1/addresses",
    filterFields: ["type" as OSType],
  },

  // ADDRESS
  address: {
    path: "/gateway/api/v1/addresses/:hash",
    pathParams: ["hash" as const],
  },
  address_counters: {
    path: "/gateway/api/v1/addresses/:hash/counters",
    pathParams: ["hash" as const],
  },
  address_tabs_counters: {
    path: "/gateway/api/v1/addresses/:hash/tabs-counters",
    pathParams: ["hash" as const],
  },
  // this resource doesn't have pagination, so causing huge problems on some addresses page
  // address_token_balances: {
  //   path: '/api/v2/addresses/:hash/token-balances',
  // },
  address_txs: {
    path: "/gateway/api/v1/addresses/:hash/transactions",
    pathParams: ["hash" as const],
    filterFields: ["filter" as const, "type" as OSType],
  },
  address_internal_txs: {
    path: "/gateway/api/v1/addresses/:hash/internal-transactions",
    pathParams: ["hash" as const],
    filterFields: ["filter" as const],
  },
  address_association: {
    path: "/gateway/api/v1/addresses/:hash/association",
    pathParams: ["hash" as const],
  },
  associations: {
    path: "/gateway/api/v1/addresses/associations",
    filterFields: ["hashes" as const],
  },
  address_token_transfers: {
    path: "/gateway/api/v1/addresses/:hash/token-transfers",
    pathParams: ["hash" as const],
    filterFields: ["filter" as const, "type" as const, "token" as const],
  },
  address_blocks_validated: {
    path: "/api/v2/addresses/:hash/blocks-validated",
    pathParams: ["hash" as const],
    filterFields: [],
  },
  address_coin_balance: {
    path: "/api/v2/addresses/:hash/coin-balance-history",
    pathParams: ["hash" as const],
    filterFields: [],
  },
  address_coin_balance_chart: {
    path: "/api/v2/addresses/:hash/coin-balance-history-by-day",
    pathParams: ["hash" as const],
  },
  address_logs: {
    path: "/api/v2/addresses/:hash/logs",
    pathParams: ["hash" as const],
    filterFields: [],
  },
  address_logs_cosmos: {
    path: "/gateway/api/v1/addresses/:hash/logs",
    pathParams: ["hash" as const],
    filterFields: [],
  },
  address_tokens: {
    path: "/gateway/api/v1/addresses/:hash/tokens",
    pathParams: ["hash" as const],
    filterFields: ["type" as const],
  },
  address_nfts: {
    path: "/gateway/api/v1/addresses/:hash/nft",
    pathParams: ["hash" as const],
    filterFields: ["type" as const],
  },
  address_collections: {
    path: "/gateway/api/v1/addresses/:hash/nft/collections",
    pathParams: ["hash" as const],
    filterFields: ["type" as const],
  },
  address_withdrawals: {
    path: "/api/v2/addresses/:hash/withdrawals",
    pathParams: ["hash" as const],
    filterFields: [],
  },
  address_native_raw: {
    path: "/gateway/api/v1/addresses/:hash/native-raw",
    pathParams: ["hash" as const],
  },

  // CONTRACT
  contract: {
    path: "/api/v2/smart-contracts/:hash",
    pathParams: ["hash" as const],
  },
  contract_methods_read: {
    path: "/api/v2/smart-contracts/:hash/methods-read",
    pathParams: ["hash" as const],
  },
  contract_methods_read_proxy: {
    path: "/api/v2/smart-contracts/:hash/methods-read-proxy",
    pathParams: ["hash" as const],
  },
  contract_method_query: {
    path: "/api/v2/smart-contracts/:hash/query-read-method",
    pathParams: ["hash" as const],
  },
  contract_methods_write: {
    path: "/api/v2/smart-contracts/:hash/methods-write",
    pathParams: ["hash" as const],
  },
  contract_methods_write_proxy: {
    path: "/api/v2/smart-contracts/:hash/methods-write-proxy",
    pathParams: ["hash" as const],
  },
  contract_verification_config: {
    path: "/api/v2/smart-contracts/verification/config",
  },
  contract_verification_via: {
    path: "/api/v2/smart-contracts/:hash/verification/via/:method",
    pathParams: ["hash" as const, "method" as const],
  },
  contract_solidityscan_report: {
    path: "/api/v2/smart-contracts/:hash/solidityscan-report",
    pathParams: ["hash" as const],
  },

  verified_contracts: {
    path: "/api/v2/smart-contracts",
    filterFields: ["q" as const, "filter" as const],
  },
  verified_contracts_counters: {
    path: "/api/v2/smart-contracts/counters",
  },

  // TOKEN
  token: {
    path: "/gateway/api/v1/tokens/:hash",
    pathParams: ["hash" as const],
  },
  token_verified_info: {
    path: "/api/v1/chains/:chainId/token-infos/:hash",
    pathParams: ["chainId" as const, "hash" as const],
    endpoint: getFeaturePayload(config.features.verifiedTokens)?.api.endpoint,
    basePath: getFeaturePayload(config.features.verifiedTokens)?.api.basePath,
  },
  token_counters: {
    path: "/gateway/api/v1/tokens/:hash/counters",
    pathParams: ["hash" as const],
  },
  token_holders: {
    path: "/gateway/api/v1/tokens/:hash/holders",
    pathParams: ["hash" as const],
    filterFields: [],
  },
  token_transfers: {
    path: "/gateway/api/v1/tokens/:hash/transfers",
    pathParams: ["hash" as const],
    filterFields: [],
  },
  token_inventory: {
    path: "/gateway/api/v1/tokens/:hash/instances",
    pathParams: ["hash" as const],
    filterFields: ["holder_address_hash" as const],
  },
  tokens: {
    path: "/gateway/api/v1/tokens",
    filterFields: ["q" as const, "type" as const],
  },
  nfts: {
    path: "/gateway/api/v1/tokens",
    filterFields: ["q" as const, "type" as const],
  },
  tokens_bridged: {
    path: "/api/v2/tokens/bridged",
    filterFields: ["q" as const, "chain_ids" as const],
  },
  native_tokens: {
    path: "/gateway/api/v1/native-tokens",
    filterFields: ["search" as const, "limit" as const, "items_count" as const],
  },

  // TOKEN INSTANCE
  token_instance: {
    path: "/gateway/api/v1/tokens/:hash/instances/:id",
    pathParams: ["hash" as const, "id" as const],
  },
  token_instance_transfers_count: {
    path: "/gateway/api/v1/tokens/:hash/instances/:id/transfers-count",
    pathParams: ["hash" as const, "id" as const],
  },
  token_instance_transfers: {
    path: "/gateway/api/v1/tokens/:hash/instances/:id/transfers",
    pathParams: ["hash" as const, "id" as const],
    filterFields: [],
  },
  token_instance_holders: {
    path: "/api/v2/tokens/:hash/instances/:id/holders",
    pathParams: ["hash" as const, "id" as const],
    filterFields: [],
  },

  // HOMEPAGE
  homepage_stats: {
    path: "/gateway/api/v1/stats",
  },
  homepage_chart_txs: {
    path: "/api/v2/stats/charts/transactions",
  },
  homepage_chart_market: {
    path: "/gateway/api/v1/stats/charts/market",
  },
  homepage_blocks: {
    path: "/api/v2/main-page/blocks",
  },
  homepage_deposits: {
    path: "/api/v2/main-page/optimism-deposits",
  },
  homepage_txs: {
    path: "/api/v2/main-page/transactions",
  },
  homepage_zkevm_l2_batches: {
    path: "/api/v2/main-page/zkevm/batches/confirmed",
  },
  homepage_txs_watchlist: {
    path: "/api/v2/main-page/transactions/watchlist",
  },
  homepage_indexing_status: {
    path: "/api/v2/main-page/indexing-status",
  },
  homepage_zkevm_latest_batch: {
    path: "/api/v2/main-page/zkevm/batches/latest-number",
  },

  // SEARCH
  quick_search: {
    path: "/gateway/api/v1/search/quick",
    filterFields: ["q"],
  },
  search: {
    path: "/api/v2/search",
    filterFields: ["q"],
  },
  search_check_redirect: {
    path: "/api/v2/search/check-redirect",
  },

  // L2
  l2_deposits: {
    path: "/api/v2/optimism/deposits",
    filterFields: [],
  },

  l2_deposits_count: {
    path: "/api/v2/optimism/deposits/count",
  },

  l2_withdrawals: {
    path: "/api/v2/optimism/withdrawals",
    filterFields: [],
  },

  l2_withdrawals_count: {
    path: "/api/v2/optimism/withdrawals/count",
  },

  l2_output_roots: {
    path: "/api/v2/optimism/output-roots",
    filterFields: [],
  },

  l2_output_roots_count: {
    path: "/api/v2/optimism/output-roots/count",
  },

  l2_txn_batches: {
    path: "/api/v2/optimism/txn-batches",
    filterFields: [],
  },

  l2_txn_batches_count: {
    path: "/api/v2/optimism/txn-batches/count",
  },

  zkevm_l2_txn_batches: {
    path: "/api/v2/zkevm/batches",
    filterFields: [],
  },

  zkevm_l2_txn_batches_count: {
    path: "/api/v2/zkevm/batches/count",
  },

  zkevm_l2_txn_batch: {
    path: "/api/v2/zkevm/batches/:number",
    pathParams: ["number" as const],
  },
  zkevm_l2_txn_batch_txs: {
    path: "/api/v2/transactions/zkevm-batch/:number",
    pathParams: ["number" as const],
    filterFields: [],
  },

  // CONFIGS
  config_backend_version: {
    path: "/api/v2/config/backend-version",
  },

  // OTHER
  api_v2_key: {
    path: "/api/v2/key",
  },

  // API V1
  csv_export_txs: {
    path: "/gateway/api/v1/export/transactions-csv",
  },
  csv_export_internal_txs: {
    path: "/api/v1/internal-transactions-csv",
  },
  csv_export_token_transfers: {
    path: "/gateway/api/v1/export/token-transfers-csv",
  },
  csv_export_logs: {
    path: "/api/v1/logs-csv",
  },
  // CSV EXPORT
  csv_export_token_holders: {
    path: "/api/v2/tokens/:hash/holders/csv",
    pathParams: ["hash" as const],
  },
  graphql: {
    path: "/api/v1/graphql",
  },
  global_public_tags: {
    path: "/gateway/api/v1/public-tags",
  },

  // Proposal
  proposal_stats: {
    path: "/gateway/api/v1/proposals/stats",
  },
  proposals: {
    path: "/gateway/api/v1/proposals",
    filterFields: [
      "status" as const,
      "limit" as const,
      "items_count" as const,
      "search" as const,
    ],
  },
  proposal: {
    path: "/gateway/api/v1/proposals/:proposal_id",
    pathParams: ["proposal_id" as const],
  },
  proposal_detail_stats: {
    path: "/gateway/api/v1/proposals/:proposal_id/stats",
    pathParams: ["proposal_id" as const],
  },
  proposal_votes: {
    path: "/gateway/api/v1/proposals/:proposal_id/votes",
    pathParams: ["proposal_id" as const],
    filterFields: [
      "answer" as const,
      "limit" as const,
      "items_count" as const,
      "search" as const,
      "vote_options" as const,
    ],
  },
  proposal_validator_votes: {
    path: "/gateway/api/v1/proposals/:proposal_id/validator-votes",
    pathParams: ["proposal_id" as const],
    filterFields: [
      "limit" as const,
      "items_count" as const,
      "search" as const,
      "vote_options" as const,
    ],
  },
  proposal_depositors: {
    path: "/gateway/api/v1/proposals/:proposal_id/depositors",
    pathParams: ["proposal_id" as const],
    filterFields: ["message_type" as const],
  },
  governance_tallying: {
    path: "/gateway/api/v1/governance/tallying",
  },

  // IBC
  ibc_chain_connecteds: {
    path: "/gateway/api/v1/ibc/relayers/chain-connected",
    filterFields: ["search" as const, "status" as const, "limit" as const],
  },
  ibc_chain_details: {
    path: "/gateway/api/v1/ibc/relayers/:chain_id",
    pathParams: ["chain_id" as const],
    filterFields: ["limit" as const, "items_count" as const],
  },
  ibc_transfer_assets: {
    path: "/gateway/api/v1/ibc/channel/:channel_id/transfer-assets/:counterparty_channel_id",
    pathParams: ["channel_id" as const, "counterparty_channel_id" as const],
    filterFields: [
      "limit" as const,
      "items_count" as const,
      "type" as const,
      "search" as const,
    ],
  },
  ibc_channel_details: {
    path: "/gateway/api/v1/ibc/channel/:channel_id/counterparty/:counterparty_channel_id",
    pathParams: ["channel_id" as const, "counterparty_channel_id" as const],
  },
  ibc_relayers_stat: {
    path: "/gateway/api/v1/ibc/relayers/stat",
  },
  ibc_channel_transactions: {
    path: "/gateway/api/v1/ibc/relayers/:channel_id/transactions",
    pathParams: ["channel_id" as const],
    filterFields: ["limit" as const, "items_count" as const, "type" as const],
  },
  ibc_tokens: {
    path: "/gateway/api/v1/ibc/tokens",
    filterFields: ["limit" as const, "items_count" as const],
  },

  // NATIVE TOKENS
  native_token: {
    path: "/gateway/api/v1/native-tokens/detail",
  },
  native_token_transfers: {
    path: "/gateway/api/v1/native-tokens/transfers",
    filterFields: ["denom" as const, "limit" as const, "items_count" as const],
  },
  native_token_holders: {
    path: "/gateway/api/v1/native-tokens/holders",
    filterFields: ["denom" as const, "limit" as const, "items_count" as const],
  },
  native_token_counters: {
    path: "/gateway/api/v1/native-tokens/counters",
    filterFields: ["denom" as const],
  },

  // ICS_20
  ics20_tokens: {
    path: "/gateway/api/v1/ics20-tokens",
    filterFields: ["limit" as const, "items_count" as const, "search" as const],
  },
  ics20_token: {
    path: "/gateway/api/v1/ics20-tokens/:hash",
    pathParams: ["hash" as const],
  },
  ics20_token_transfers: {
    path: "/gateway/api/v1/ics20-tokens/:hash/transfers",
    pathParams: ["hash" as const],
    filterFields: ["limit" as const, "items_count" as const],
  },
  ics20_token_holders: {
    path: "/gateway/api/v1/ics20-tokens/:hash/holders",
    pathParams: ["hash" as const],
    filterFields: ["limit" as const, "items_count" as const],
  },
  ics20_token_counters: {
    path: "/gateway/api/v1/ics20-tokens/:hash/counters",
    pathParams: ["hash" as const],
  },

  // params
  params_node_info: {
    path: "/gateway/api/v1/chain-parameters/native/node-info",
  },
  params_staking_pool: {
    path: "/gateway/api/v1/chain-parameters/native/staking-pool",
  },
  params_status: {
    path: "/gateway/api/v1/chain-parameters/native/status",
  },
  params_genesis: {
    path: "/gateway/api/v1/chain-parameters/native/genesis",
  },
  params_abci_info: {
    path: "/gateway/api/v1/chain-parameters/native/abci-info",
  },
  params_total_supply: {
    path: "/gateway/api/v1/chain-parameters/native/total-supply",
  },
  params_staking: {
    path: "/gateway/api/v1/chain-parameters/native/staking",
  },
  params_slashing: {
    path: "/gateway/api/v1/chain-parameters/native/slashing",
  },
  params_distribution: {
    path: "/gateway/api/v1/chain-parameters/native/distribution",
  },
  params_tallying: {
    path: "/gateway/api/v1/chain-parameters/native/governance/tallying",
  },
  params_inflation: {
    path: "/gateway/api/v1/chain-parameters/native/inflation",
  },
  params_voting: {
    path: "/gateway/api/v1/chain-parameters/native/governance/voting",
  },
  params_deposit: {
    path: "/gateway/api/v1/chain-parameters/native/governance/deposit",
  },
};

export type ResourceName = keyof typeof RESOURCES;

type ResourcePathMap = {
  [K in ResourceName]: (typeof RESOURCES)[K]["path"];
};
export type ResourcePath = ResourcePathMap[keyof ResourcePathMap];

export type ResourceFiltersKey<R extends ResourceName> =
  (typeof RESOURCES)[R] extends { filterFields: Array<unknown> }
    ? ArrayElement<(typeof RESOURCES)[R]["filterFields"]>
    : never;

export const resourceKey = (x: keyof typeof RESOURCES) => x;

type ResourcePathParamName<Resource extends ResourceName> =
  (typeof RESOURCES)[Resource] extends { pathParams: Array<string> }
    ? ArrayElement<(typeof RESOURCES)[Resource]["pathParams"]>
    : string;

export type ResourcePathParams<Resource extends ResourceName> =
  (typeof RESOURCES)[Resource] extends { pathParams: Array<string> }
    ? Record<ResourcePathParamName<Resource>, string | undefined>
    : never;

export interface ResourceError<T = unknown> {
  payload?: T;
  status: Response["status"];
  statusText: Response["statusText"];
}

export type ResourceErrorAccount<T> = ResourceError<{ errors: T }>;

export type PaginatedResources =
  | "blocks"
  | "block_txs"
  | "txs_validated"
  | "latest_txs_validated"
  | "txs_pending"
  | "txs_watchlist"
  | "txs_execution_node"
  | "tx_internal_txs"
  | "tx_logs"
  | "tx_token_transfers"
  | "tx_state_changes"
  | "addresses"
  | "address_txs"
  | "address_internal_txs"
  | "address_token_transfers"
  | "address_blocks_validated"
  | "address_coin_balance"
  | "search"
  | "address_logs"
  | "address_logs_cosmos"
  | "address_tokens"
  | "address_nfts"
  | "address_collections"
  | "token_transfers"
  | "token_holders"
  | "token_inventory"
  | "tokens"
  | "nfts"
  | "tokens_bridged"
  | "token_instance_transfers"
  | "token_instance_holders"
  | "verified_contracts"
  | "validators"
  | "validator"
  | "validators_stats"
  | "l2_output_roots"
  | "l2_withdrawals"
  | "l2_txn_batches"
  | "l2_deposits"
  | "zkevm_l2_txn_batches"
  | "zkevm_l2_txn_batch_txs"
  | "withdrawals"
  | "address_withdrawals"
  | "block_withdrawals"
  | "watchlist_evm"
  | "watchlists"
  | "proposals"
  | "proposal_votes"
  | "proposal_validator_votes"
  | "proposal_depositors"
  | "user_package"
  | "api_package"
  | "ibc_chain_connecteds"
  | "ibc_chain_details"
  | "public_packages"
  | "user_package_profile_list"
  | "private_tags"
  | "ibc_transfer_assets"
  | "ibc_channel_transactions"
  | "ibc_tokens"
  | "ics20_tokens"
  | "ics20_token_transfers"
  | "ics20_token_holders"
  | "native_token_transfers"
  | "native_token_holders"
  | "native_tokens"
  | "get_api_key_list"
  | "get_api_resource_list"
  | "get_api_key_activities"
  | "admin_get_user_package_list";

export type PaginatedResponse<Q extends PaginatedResources> =
  ResourcePayload<Q>;

/* eslint-disable @typescript-eslint/indent */
export type ResourcePayload<Q extends ResourceName> = Q extends "checkpoints"
  ? Checkpoints
  : Q extends "register"
    ? RegisterResponse
    : Q extends "exchange_code"
      ? ExchangeCodeResponse
      : Q extends "user_info"
        ? UserInfo
        : Q extends "workspace_userinfo"
          ? WorkspaceUserInfo
          : Q extends "custom_abi"
            ? CustomAbis
            : Q extends "public_tags"
              ? PublicTags
              : Q extends "global_public_tags"
                ? GlobalPublicTagsResponse
                : Q extends "verified_addresses"
                  ? VerifiedAddressResponse
                  : Q extends "validators"
                    ? ValidatorInfosResponse
                    : Q extends "validator"
                      ? ValidatorDetailResponse
                      : Q extends "validators_stats"
                        ? ValidatorsStatsResponse
                        : Q extends "token_info_applications_config"
                          ? TokenInfoApplicationConfig
                          : Q extends "token_info_applications"
                            ? TokenInfoApplications
                            : Q extends "homepage_stats"
                              ? HomeStats
                              : Q extends "homepage_chart_txs"
                                ? ChartTransactionResponse
                                : Q extends "homepage_chart_market"
                                  ? ChartMarketResponse
                                  : Q extends "homepage_blocks"
                                    ? Array<Block>
                                    : Q extends "homepage_txs"
                                      ? Array<Transaction>
                                      : Q extends "homepage_txs_watchlist"
                                        ? Array<Transaction>
                                        : Q extends "homepage_deposits"
                                          ? Array<L2DepositsItem>
                                          : Q extends "homepage_zkevm_l2_batches"
                                            ? {
                                                items: Array<ZkEvmL2TxnBatchesItem>;
                                              }
                                            : Q extends "homepage_indexing_status"
                                              ? IndexingStatus
                                              : Q extends "homepage_zkevm_latest_batch"
                                                ? number
                                                : Q extends "stats_counters"
                                                  ? Counters
                                                  : Q extends "stats_lines"
                                                    ? StatsCharts
                                                    : Q extends "stats_line"
                                                      ? StatsChart
                                                      : Q extends "blocks"
                                                        ? BlocksResponse
                                                        : Q extends "request_challenge"
                                                          ? any
                                                          : Q extends "block"
                                                            ? Block
                                                            : Q extends "block_txs"
                                                              ? BlockTransactionsResponse
                                                              : Q extends "block_withdrawals"
                                                                ? BlockWithdrawalsResponse
                                                                : Q extends "txs_validated"
                                                                  ? TransactionsResponseValidated
                                                                  : Q extends "latest_txs_validated"
                                                                    ? TransactionsResponseValidated
                                                                    : Q extends "txs_pending"
                                                                      ? TransactionsResponsePending
                                                                      : Q extends "txs_watchlist"
                                                                        ? TransactionsResponseWatchlist
                                                                        : Q extends "txs_execution_node"
                                                                          ? TransactionsResponseValidated
                                                                          : Q extends "tx"
                                                                            ? Transaction
                                                                            : Q extends "tx_internal_txs"
                                                                              ? InternalTransactionsResponse
                                                                              : Q extends "tx_logs"
                                                                                ? LogsResponseTx
                                                                                : Q extends "tx_token_transfers"
                                                                                  ? TokenTransferResponse
                                                                                  : Q extends "tx_raw_trace"
                                                                                    ? RawTracesResponse
                                                                                    : Q extends "tx_state_changes"
                                                                                      ? TxStateChanges
                                                                                      : Q extends "tx_interpretation"
                                                                                        ? TxInterpretationResponse
                                                                                        : Q extends "addresses"
                                                                                          ? AddressesResponse
                                                                                          : Q extends "address"
                                                                                            ? Address
                                                                                            : Q extends "address_counters"
                                                                                              ? AddressCounters
                                                                                              : Q extends "address_tabs_counters"
                                                                                                ? AddressTabsCounters
                                                                                                : Q extends "address_txs"
                                                                                                  ? AddressTransactionsResponse
                                                                                                  : Q extends "address_internal_txs"
                                                                                                    ? AddressInternalTxsResponse
                                                                                                    : Q extends "address_token_transfers"
                                                                                                      ? AddressTokenTransferResponse
                                                                                                      : Q extends "address_blocks_validated"
                                                                                                        ? AddressBlocksValidatedResponse
                                                                                                        : Q extends "address_coin_balance"
                                                                                                          ? AddressCoinBalanceHistoryResponse
                                                                                                          : Q extends "address_coin_balance_chart"
                                                                                                            ? AddressCoinBalanceHistoryChart
                                                                                                            : Q extends "address_logs"
                                                                                                              ? LogsResponseAddress
                                                                                                              : Q extends "address_logs_cosmos"
                                                                                                                ? LogsCosmosResponseAddress
                                                                                                                : Q extends "address_tokens"
                                                                                                                  ? AddressTokensResponse
                                                                                                                  : Q extends "address_nfts"
                                                                                                                    ? AddressNFTsResponse
                                                                                                                    : Q extends "address_collections"
                                                                                                                      ? AddressCollectionsResponse
                                                                                                                      : Q extends "address_withdrawals"
                                                                                                                        ? AddressWithdrawalsResponse
                                                                                                                        : Q extends "token"
                                                                                                                          ? TokenInfo
                                                                                                                          : Q extends "token_verified_info"
                                                                                                                            ? TokenVerifiedInfo
                                                                                                                            : Q extends "token_counters"
                                                                                                                              ? TokenCounters
                                                                                                                              : Q extends "token_transfers"
                                                                                                                                ? TokenTransferResponse
                                                                                                                                : Q extends "token_holders"
                                                                                                                                  ? TokenHolders
                                                                                                                                  : Q extends "token_instance"
                                                                                                                                    ? TokenInstance
                                                                                                                                    : Q extends "token_instance_transfers_count"
                                                                                                                                      ? TokenInstanceTransfersCount
                                                                                                                                      : Q extends "token_instance_transfers"
                                                                                                                                        ? TokenInstanceTransferResponse
                                                                                                                                        : Q extends "token_instance_holders"
                                                                                                                                          ? TokenHolders
                                                                                                                                          : Q extends "token_inventory"
                                                                                                                                            ? TokenInventoryResponse
                                                                                                                                            : Q extends "tokens"
                                                                                                                                              ? TokensResponse
                                                                                                                                              : Q extends "nfts"
                                                                                                                                                ? TokensResponse
                                                                                                                                                : Q extends "tokens_bridged"
                                                                                                                                                  ? TokensResponse
                                                                                                                                                  : Q extends "quick_search"
                                                                                                                                                    ? Array<SearchResultItem>
                                                                                                                                                    : Q extends "search"
                                                                                                                                                      ? SearchResult
                                                                                                                                                      : Q extends "search_check_redirect"
                                                                                                                                                        ? SearchRedirectResult
                                                                                                                                                        : Q extends "contract"
                                                                                                                                                          ? SmartContract
                                                                                                                                                          : Q extends "contract_methods_read"
                                                                                                                                                            ? Array<SmartContractReadMethod>
                                                                                                                                                            : Q extends "contract_methods_read_proxy"
                                                                                                                                                              ? Array<SmartContractReadMethod>
                                                                                                                                                              : Q extends "contract_methods_write"
                                                                                                                                                                ? Array<SmartContractWriteMethod>
                                                                                                                                                                : Q extends "contract_methods_write_proxy"
                                                                                                                                                                  ? Array<SmartContractWriteMethod>
                                                                                                                                                                  : Q extends "contract_solidityscan_report"
                                                                                                                                                                    ? SolidityscanReport
                                                                                                                                                                    : Q extends "verified_contracts"
                                                                                                                                                                      ? VerifiedContractsResponse
                                                                                                                                                                      : Q extends "verified_contracts_counters"
                                                                                                                                                                        ? VerifiedContractsCounters
                                                                                                                                                                        : Q extends "visualize_sol2uml"
                                                                                                                                                                          ? VisualizedContract
                                                                                                                                                                          : Q extends "contract_verification_config"
                                                                                                                                                                            ? SmartContractVerificationConfig
                                                                                                                                                                            : Q extends "withdrawals"
                                                                                                                                                                              ? WithdrawalsResponse
                                                                                                                                                                              : Q extends "withdrawals_counters"
                                                                                                                                                                                ? WithdrawalsCounters
                                                                                                                                                                                : Q extends "l2_output_roots"
                                                                                                                                                                                  ? L2OutputRootsResponse
                                                                                                                                                                                  : Q extends "l2_withdrawals"
                                                                                                                                                                                    ? L2WithdrawalsResponse
                                                                                                                                                                                    : Q extends "l2_deposits"
                                                                                                                                                                                      ? L2DepositsResponse
                                                                                                                                                                                      : Q extends "l2_txn_batches"
                                                                                                                                                                                        ? L2TxnBatchesResponse
                                                                                                                                                                                        : Q extends "l2_output_roots_count"
                                                                                                                                                                                          ? number
                                                                                                                                                                                          : Q extends "l2_withdrawals_count"
                                                                                                                                                                                            ? number
                                                                                                                                                                                            : Q extends "l2_deposits_count"
                                                                                                                                                                                              ? number
                                                                                                                                                                                              : Q extends "l2_withdrawals_count"
                                                                                                                                                                                                ? number
                                                                                                                                                                                                : Q extends "l2_deposits_count"
                                                                                                                                                                                                  ? number
                                                                                                                                                                                                  : Q extends "proposal_stats"
                                                                                                                                                                                                    ? ProposalStats
                                                                                                                                                                                                    : Q extends "proposals"
                                                                                                                                                                                                      ? ProposalResponse
                                                                                                                                                                                                      : Q extends "proposal"
                                                                                                                                                                                                        ? ProposalDetailsResponse
                                                                                                                                                                                                        : Q extends "proposal_votes"
                                                                                                                                                                                                          ? ProposalVoteResponse
                                                                                                                                                                                                          : Q extends "proposal_validator_votes"
                                                                                                                                                                                                            ? ProposalValidatorVoteResponse
                                                                                                                                                                                                            : Q extends "proposal_depositors"
                                                                                                                                                                                                              ? ProposalDepositorResponse
                                                                                                                                                                                                              : Q extends "proposal_detail_stats"
                                                                                                                                                                                                                ? ProposalDetailStatsResponse
                                                                                                                                                                                                                : Q extends "governance_tallying"
                                                                                                                                                                                                                  ? ProposalGovernanceTallyingResponse
                                                                                                                                                                                                                  : Q extends "config_backend_version"
                                                                                                                                                                                                                    ? BackendVersionConfig
                                                                                                                                                                                                                    : Q extends "update_avatar"
                                                                                                                                                                                                                      ? UpdateAvatarResponse
                                                                                                                                                                                                                      : Q extends "address_association"
                                                                                                                                                                                                                        ? Association
                                                                                                                                                                                                                        : Q extends "watchlists"
                                                                                                                                                                                                                          ? WatchlistsResponse
                                                                                                                                                                                                                          : Q extends "user_package"
                                                                                                                                                                                                                            ? GetUserPackageResponse
                                                                                                                                                                                                                            : Q extends "api_package"
                                                                                                                                                                                                                              ? GetPackageResponse
                                                                                                                                                                                                                              : Q extends "api_package_detail"
                                                                                                                                                                                                                                ? PackageEntity
                                                                                                                                                                                                                                : Q extends "watchlist_evm"
                                                                                                                                                                                                                                  ? WatchlistEVMResponse
                                                                                                                                                                                                                                  : Q extends "ibc_chain_connecteds"
                                                                                                                                                                                                                                    ? IBCChainConnectedResponse
                                                                                                                                                                                                                                    : Q extends "ibc_chain_details"
                                                                                                                                                                                                                                      ? IBCChainDetailsResponse
                                                                                                                                                                                                                                      : Q extends "associations"
                                                                                                                                                                                                                                        ? Record<
                                                                                                                                                                                                                                            string,
                                                                                                                                                                                                                                            Association
                                                                                                                                                                                                                                          >
                                                                                                                                                                                                                                        : Q extends "watchlist_byaddress"
                                                                                                                                                                                                                                          ? WatchlistAddress & {
                                                                                                                                                                                                                                              association?: WatchlistAddress;
                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                          : Q extends "watchlist_evm"
                                                                                                                                                                                                                                            ? WatchlistEVMResponse
                                                                                                                                                                                                                                            : Q extends "ibc_chain_connecteds"
                                                                                                                                                                                                                                              ? IBCChainConnectedResponse
                                                                                                                                                                                                                                              : Q extends "ibc_chain_details"
                                                                                                                                                                                                                                                ? IBCChainDetailsResponse
                                                                                                                                                                                                                                                : Q extends "associations"
                                                                                                                                                                                                                                                  ? Record<
                                                                                                                                                                                                                                                      string,
                                                                                                                                                                                                                                                      Association
                                                                                                                                                                                                                                                    >
                                                                                                                                                                                                                                                  : Q extends "watchlist_byaddress"
                                                                                                                                                                                                                                                    ? WatchlistAddress & {
                                                                                                                                                                                                                                                        association?: WatchlistAddress;
                                                                                                                                                                                                                                                      }
                                                                                                                                                                                                                                                    : Q extends "my_quota"
                                                                                                                                                                                                                                                      ? UserQuota
                                                                                                                                                                                                                                                      : Q extends "public_packages"
                                                                                                                                                                                                                                                        ? GetPublicPackagesResponse
                                                                                                                                                                                                                                                        : Q extends "package_info"
                                                                                                                                                                                                                                                          ? PackageEntity
                                                                                                                                                                                                                                                          : Q extends "user_package_profile"
                                                                                                                                                                                                                                                            ? UserPackageProfileExtendedEntity
                                                                                                                                                                                                                                                            : Q extends "user_package_profile_list"
                                                                                                                                                                                                                                                              ? UserPackageProfilePaginatedList
                                                                                                                                                                                                                                                              : Q extends "latest_user_package"
                                                                                                                                                                                                                                                                ? UserPackageEntity
                                                                                                                                                                                                                                                                : Q extends "active_package"
                                                                                                                                                                                                                                                                  ? UserPackageSimplifiedEntity
                                                                                                                                                                                                                                                                  : Q extends "proposal_stats"
                                                                                                                                                                                                                                                                    ? ProposalStats
                                                                                                                                                                                                                                                                    : Q extends "proposals"
                                                                                                                                                                                                                                                                      ? ProposalResponse
                                                                                                                                                                                                                                                                      : Q extends "proposal"
                                                                                                                                                                                                                                                                        ? ProposalDetailsResponse
                                                                                                                                                                                                                                                                        : Q extends "proposal_votes"
                                                                                                                                                                                                                                                                          ? ProposalVoteResponse
                                                                                                                                                                                                                                                                          : Q extends "proposal_validator_votes"
                                                                                                                                                                                                                                                                            ? ProposalValidatorVoteResponse
                                                                                                                                                                                                                                                                            : Q extends "proposal_depositors"
                                                                                                                                                                                                                                                                              ? ProposalDepositorResponse
                                                                                                                                                                                                                                                                              : Q extends "proposal_detail_stats"
                                                                                                                                                                                                                                                                                ? ProposalDetailStatsResponse
                                                                                                                                                                                                                                                                                : Q extends "governance_tallying"
                                                                                                                                                                                                                                                                                  ? ProposalGovernanceTallyingResponse
                                                                                                                                                                                                                                                                                  : Q extends "config_backend_version"
                                                                                                                                                                                                                                                                                    ? BackendVersionConfig
                                                                                                                                                                                                                                                                                    : Q extends "update_avatar"
                                                                                                                                                                                                                                                                                      ? UpdateAvatarResponse
                                                                                                                                                                                                                                                                                      : Q extends "address_association"
                                                                                                                                                                                                                                                                                        ? Association
                                                                                                                                                                                                                                                                                        : Q extends "watchlists"
                                                                                                                                                                                                                                                                                          ? WatchlistsResponse
                                                                                                                                                                                                                                                                                          : Q extends "watchlist_evm"
                                                                                                                                                                                                                                                                                            ? WatchlistEVMResponse
                                                                                                                                                                                                                                                                                            : Q extends "ibc_chain_connecteds"
                                                                                                                                                                                                                                                                                              ? IBCChainConnectedResponse
                                                                                                                                                                                                                                                                                              : Q extends "ibc_chain_details"
                                                                                                                                                                                                                                                                                                ? IBCChainDetailsResponse
                                                                                                                                                                                                                                                                                                : Q extends "associations"
                                                                                                                                                                                                                                                                                                  ? Record<
                                                                                                                                                                                                                                                                                                      string,
                                                                                                                                                                                                                                                                                                      Association
                                                                                                                                                                                                                                                                                                    >
                                                                                                                                                                                                                                                                                                  : Q extends "watchlist_byaddress"
                                                                                                                                                                                                                                                                                                    ? WatchlistAddress
                                                                                                                                                                                                                                                                                                    : Q extends "my_quota"
                                                                                                                                                                                                                                                                                                      ? UserQuota
                                                                                                                                                                                                                                                                                                      : Q extends "private_tags"
                                                                                                                                                                                                                                                                                                        ? PrivateTagsResponse
                                                                                                                                                                                                                                                                                                        : Q extends "private_tag"
                                                                                                                                                                                                                                                                                                          ? PrivateTag
                                                                                                                                                                                                                                                                                                          : Q extends "private_tag_byhash"
                                                                                                                                                                                                                                                                                                            ? PrivateTag
                                                                                                                                                                                                                                                                                                            : Q extends "ibc_channel_details"
                                                                                                                                                                                                                                                                                                              ? IIBCRelayerChannel
                                                                                                                                                                                                                                                                                                              : Q extends "ibc_relayers_stat"
                                                                                                                                                                                                                                                                                                                ? IIBCRelayersStat
                                                                                                                                                                                                                                                                                                                : Q extends "ibc_transfer_assets"
                                                                                                                                                                                                                                                                                                                  ? IIBCRelayerChannelTransferAssetsResponse
                                                                                                                                                                                                                                                                                                                  : Q extends "ibc_channel_transactions"
                                                                                                                                                                                                                                                                                                                    ? IIBCChannelTransactionsResponse
                                                                                                                                                                                                                                                                                                                    : Q extends "ibc_tokens"
                                                                                                                                                                                                                                                                                                                      ? IIBCTokensResponse
                                                                                                                                                                                                                                                                                                                      : Q extends "address_native_raw"
                                                                                                                                                                                                                                                                                                                        ? {
                                                                                                                                                                                                                                                                                                                            data: {
                                                                                                                                                                                                                                                                                                                              view_address_stats: [
                                                                                                                                                                                                                                                                                                                                HoroscopeAddressStatsResponse,
                                                                                                                                                                                                                                                                                                                              ];
                                                                                                                                                                                                                                                                                                                            };
                                                                                                                                                                                                                                                                                                                          }
                                                                                                                                                                                                                                                                                                                        : Q extends "ics20_tokens"
                                                                                                                                                                                                                                                                                                                          ? TokenICS20sResponse
                                                                                                                                                                                                                                                                                                                          : Q extends
                                                                                                                                                                                                                                                                                                                                | "ics20_token"
                                                                                                                                                                                                                                                                                                                                | "native_token"
                                                                                                                                                                                                                                                                                                                            ? TokenInfo
                                                                                                                                                                                                                                                                                                                            : Q extends "ics20_token_transfers"
                                                                                                                                                                                                                                                                                                                              ? IIBCTokenTransfersResponse
                                                                                                                                                                                                                                                                                                                              : Q extends "ics20_token_holders"
                                                                                                                                                                                                                                                                                                                                ? IIBCTokenHoldersResponse
                                                                                                                                                                                                                                                                                                                                : Q extends "ics20_token_counters"
                                                                                                                                                                                                                                                                                                                                  ? IIBCTokenCounters
                                                                                                                                                                                                                                                                                                                                  : Q extends "params_node_info"
                                                                                                                                                                                                                                                                                                                                    ? INodeInfo
                                                                                                                                                                                                                                                                                                                                    : Q extends "params_staking_pool"
                                                                                                                                                                                                                                                                                                                                      ? IStakingPool
                                                                                                                                                                                                                                                                                                                                      : Q extends "params_status"
                                                                                                                                                                                                                                                                                                                                        ? IChainStatus
                                                                                                                                                                                                                                                                                                                                        : Q extends "params_genesis"
                                                                                                                                                                                                                                                                                                                                          ? IGenesis
                                                                                                                                                                                                                                                                                                                                          : Q extends "params_abci_info"
                                                                                                                                                                                                                                                                                                                                            ? IABCI
                                                                                                                                                                                                                                                                                                                                            : Q extends "params_total_supply"
                                                                                                                                                                                                                                                                                                                                              ? ITotalSupply
                                                                                                                                                                                                                                                                                                                                              : Q extends "params_staking"
                                                                                                                                                                                                                                                                                                                                                ? IStaking
                                                                                                                                                                                                                                                                                                                                                : Q extends "params_slashing"
                                                                                                                                                                                                                                                                                                                                                  ? ISlashing
                                                                                                                                                                                                                                                                                                                                                  : Q extends "params_distribution"
                                                                                                                                                                                                                                                                                                                                                    ? IDistribution
                                                                                                                                                                                                                                                                                                                                                    : Q extends "params_tallying"
                                                                                                                                                                                                                                                                                                                                                      ? ITallying
                                                                                                                                                                                                                                                                                                                                                      : Q extends "params_inflation"
                                                                                                                                                                                                                                                                                                                                                        ? IInflation
                                                                                                                                                                                                                                                                                                                                                        : Q extends "params_voting"
                                                                                                                                                                                                                                                                                                                                                          ? IVoting
                                                                                                                                                                                                                                                                                                                                                          : Q extends "params_deposit"
                                                                                                                                                                                                                                                                                                                                                            ? IDeposit
                                                                                                                                                                                                                                                                                                                                                            : Q extends "native_tokens"
                                                                                                                                                                                                                                                                                                                                                              ? TokenNativesResponse
                                                                                                                                                                                                                                                                                                                                                              : Q extends "native_token_transfers"
                                                                                                                                                                                                                                                                                                                                                                ? INativeTokenTransfersResponse
                                                                                                                                                                                                                                                                                                                                                                : Q extends "native_token_holders"
                                                                                                                                                                                                                                                                                                                                                                  ? INativeTokenHoldersResponse
                                                                                                                                                                                                                                                                                                                                                                  : Q extends "native_token_counters"
                                                                                                                                                                                                                                                                                                                                                                    ? INativeTokenCounters
                                                                                                                                                                                                                                                                                                                                                                    : Q extends "create_api_key"
                                                                                                                                                                                                                                                                                                                                                                      ? ApiKeyRawEntity
                                                                                                                                                                                                                                                                                                                                                                      : Q extends "update_api_key"
                                                                                                                                                                                                                                                                                                                                                                        ? ApiKeyRawEntity
                                                                                                                                                                                                                                                                                                                                                                        : Q extends "get_api_key_list"
                                                                                                                                                                                                                                                                                                                                                                          ? ApiKeyPaginatedList
                                                                                                                                                                                                                                                                                                                                                                          : Q extends "get_api_resource_list"
                                                                                                                                                                                                                                                                                                                                                                            ? ApiResourcePaginatedList
                                                                                                                                                                                                                                                                                                                                                                            : Q extends "add_api_resource"
                                                                                                                                                                                                                                                                                                                                                                              ? ApiResourceRawEntity
                                                                                                                                                                                                                                                                                                                                                                              : Q extends "update_api_resource"
                                                                                                                                                                                                                                                                                                                                                                                ? ApiResourceRawEntity
                                                                                                                                                                                                                                                                                                                                                                                : Q extends "admin_create_assign_user_package"
                                                                                                                                                                                                                                                                                                                                                                                  ? UserPackageEntity
                                                                                                                                                                                                                                                                                                                                                                                  : Q extends "get_api_key_activities"
                                                                                                                                                                                                                                                                                                                                                                                    ? PaginatedApiKeyActivityList
                                                                                                                                                                                                                                                                                                                                                                                    : Q extends "admin_get_user_package_list"
                                                                                                                                                                                                                                                                                                                                                                                      ? UserPackagePaginatedList
                                                                                                                                                                                                                                                                                                                                                                                      : Q extends "admin_get_specific_user_package_list"
                                                                                                                                                                                                                                                                                                                                                                                        ? UserPackagePaginatedList
                                                                                                                                                                                                                                                                                                                                                                                        : Q extends "get_user_package_profile"
                                                                                                                                                                                                                                                                                                                                                                                          ? UserPackageProfileExtendedEntity
                                                                                                                                                                                                                                                                                                                                                                                          : Q extends "user_get_latest_user_package"
                                                                                                                                                                                                                                                                                                                                                                                            ? UserPackageSimplifiedEntity
                                                                                                                                                                                                                                                                                                                                                                                            : Q extends "admin_get_user_package_profile_stats"
                                                                                                                                                                                                                                                                                                                                                                                              ? CustomerStatsEntity
                                                                                                                                                                                                                                                                                                                                                                                              : Q extends "packages_stats"
                                                                                                                                                                                                                                                                                                                                                                                                ? IPackagesStats
                                                                                                                                                                                                                                                                                                                                                                                                : never;
/* eslint-enable @typescript-eslint/indent */

/* eslint-disable @typescript-eslint/indent */
export type PaginationFilters<Q extends PaginatedResources> = Q extends "blocks"
  ? BlockFilters
  : Q extends "txs_validated" | "txs_pending" | "latest_txs_validated"
    ? TTxsFilters
    : Q extends "reset_password"
      ? ResetPasswordFilters
      : Q extends "register"
        ? RegisterFilters
        : Q extends "exchange_code"
          ? ExchangeCodeFilters
          : Q extends "tx_token_transfers"
            ? TokenTransferFilters
            : Q extends "token_transfers"
              ? TokenTransferFilters
              : Q extends "address_txs" | "address_internal_txs"
                ? AddressTxsFilters
                : Q extends "address_token_transfers"
                  ? AddressTokenTransferFilters
                  : Q extends "address_tokens"
                    ? AddressTokensFilter
                    : Q extends "address_nfts"
                      ? AddressNFTTokensFilter
                      : Q extends "address_collections"
                        ? AddressNFTTokensFilter
                        : Q extends "search"
                          ? SearchResultFilters
                          : Q extends "token_inventory"
                            ? TokenInventoryFilters
                            : Q extends "tokens"
                              ? TokensFilters
                              : Q extends "nfts"
                                ? TokensFilters
                                : Q extends "tokens_bridged"
                                  ? TokensBridgedFilters
                                  : Q extends "verified_contracts"
                                    ? VerifiedContractsFilters
                                    : Q extends "block_txs"
                                      ? BlockDetailFilter
                                      : Q extends "validators"
                                        ? ValidatorsFilter
                                        : Q extends "validator"
                                          ? ValidatorDetailFilter
                                          : Q extends "proposals"
                                            ? ProposalFilters
                                            : Q extends "proposal"
                                              ? ProposalDetailFilters
                                              : Q extends "proposal_votes"
                                                ? ProposalVoteFilters
                                                : Q extends "proposal_validator_votes"
                                                  ? ProposalValidatorVoteFilters
                                                  : Q extends "proposal_depositors"
                                                    ? ProposalDepositorFilters
                                                    : Q extends "user_package"
                                                      ? UserPackagePaginatedQueryParams
                                                      : Q extends "api_package"
                                                        ? PackagePaginatedQueryParams
                                                        : Q extends "watchlists"
                                                          ? WatchlistsFilters
                                                          : Q extends "user_package"
                                                            ? UserPackagePaginatedQueryParams
                                                            : Q extends "public_packages"
                                                              ? GetPublicPackagesParams
                                                              : Q extends "user_package_profile_list"
                                                                ? UserPackageProfilePaginatedQueryParams
                                                                : Q extends "watchlists"
                                                                  ? WatchlistsFilters
                                                                  : Q extends "private_tags"
                                                                    ? PrivateTagsFilters
                                                                    : Q extends "ibc_transfer_assets"
                                                                      ? IIBCRelayerChannelTransferAssetsFilters
                                                                      : Q extends "ibc_channel_transactions"
                                                                        ? Omit<
                                                                            DefaultFilter,
                                                                            "type"
                                                                          > & {
                                                                            type?:
                                                                              | IIBCRelayerTransferType
                                                                              | "";
                                                                          }
                                                                        : Q extends "get_api_key_list"
                                                                          ? QueryApiKeyParams
                                                                          : Q extends "get_api_key"
                                                                            ? ApiKeyWithStatsEntity
                                                                            : Q extends "get_api_resource_list"
                                                                              ? ApiResourceQueryParams
                                                                              : Q extends "get_api_key_activities"
                                                                                ? QueryApiKeyActivityQueryParams
                                                                                : Q extends "admin_get_user_package_list"
                                                                                  ? UserPackagePaginatedQueryParams
                                                                                  : Q extends "ibc_chain_connecteds"
                                                                                    ? IIBCChainConnectedsFilters
                                                                                    : DefaultFilter;
/* eslint-enable @typescript-eslint/indent */

/* eslint-disable @typescript-eslint/indent */
export type PaginationSorting<Q extends PaginatedResources> = Q extends "tokens"
  ? TokensSorting
  : Q extends "nfts"
    ? TokensSorting
    : Q extends "tokens_bridged"
      ? TokensSorting
      : Q extends "verified_contracts"
        ? VerifiedContractsSorting
        : Q extends "address_txs"
          ? TransactionsSorting
          : never;
/* eslint-enable @typescript-eslint/indent */
