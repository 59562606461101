import config from "configs/app";
import { ChainConfig, ChainKey } from "configs/app/chainConfigs";
import type { NavItemInternal } from "types/client/navigation-items";

type ChainNavItem = Omit<NavItemInternal, "nextRoute"> & ChainConfig;

export default function getNavChains(
  currentChainKey: ChainKey | undefined,
): Array<ChainNavItem> {
  const chainNavItems = config.chain.configs
    .map((config) => ({
      ...config,
      text: config.chainName,
      isActive: config.chainKey === currentChainKey,
      disabled: config.disabled || false,
    }))
    .filter(Boolean);

  return chainNavItems;
}
