import { Route } from "nextjs-routes";
import { OnChangeTabItemProps, ProfileSideBarSectionItem } from "./types";

export enum WORKSPACE_PROFILE_ROLE {
  USER = "user",
  ADMIN = "admin",
}

export const USER_DASHBOARD_NAVS: ProfileSideBarSectionItem[] = [
  {
    id: "others",
    title: "Others",
    userRolesForShow: [WORKSPACE_PROFILE_ROLE.USER],
    // userRolesForHide: ["admin"],
    items: [
      {
        id: "api_keys",
        icon: "api-keys",
        title: "API Keys",
        href: "/auth/profile/api-keys",
      },
    ],
  },
];

export const ADMIN_DASHBOARD_NAVS: ProfileSideBarSectionItem[] = [
  {
    id: "apis",
    title: "APIs",
    userRolesForShow: [WORKSPACE_PROFILE_ROLE.ADMIN],
    items: [
      {
        id: "customers",
        icon: "api-keys",
        title: "Customers",

        href: "/auth/profile/admin/api-dashboard/customers",
        subItems: [
          {
            id: "customer_details",
            icon: "api-keys",
            title: "Customer details",
            href: "/auth/profile/admin/api-dashboard/customer/[id]",
          },
        ],
      },
      {
        id: "packages",
        icon: "block",
        title: "Packages",
        href: "/auth/profile/admin/api-dashboard/packages",
        subItems: [
          {
            id: "api_package",
            icon: "block",
            title: "API Packages",
            href: "/auth/profile/admin/api-dashboard/packages/[id]",
          },
        ],
      },
      {
        id: "credit_definition",
        icon: "owner",
        title: "Credit definition",
        href: "/auth/profile/admin/api-dashboard/credit-definition",
      },
      {
        id: "payment_list",
        icon: "description",
        title: "Payment list",
        href: "/auth/profile/admin/api-dashboard/payment-list",
      },
    ],
  },
];

export const navs: ProfileSideBarSectionItem[] = [
  {
    id: "account",
    title: "Account",
    items: [
      {
        id: "default",
        icon: "user",
        title: "Account settings",
        href: "/auth/profile/account",
      },
    ],
  },
  {
    id: "lists",
    title: "Lists",
    items: [
      {
        id: "watch_list",
        icon: "star_outline",
        title: "Watch list",
        href: "/auth/profile/watchlist",
      },
      {
        id: "private_tags",
        icon: "privattags",
        title: "Private tags",
        href: "/auth/profile/private-tags",
        // isDisabled: true,
        // isComingSoon: true,
      },
    ],
  },
  ...ADMIN_DASHBOARD_NAVS,
  ...USER_DASHBOARD_NAVS,
];

export const getActiveTabByPathname = (
  pathname: string,
): OnChangeTabItemProps => {
  for (const nav of navs) {
    for (const item of nav.items) {
      if (item.href === pathname) return item;
      if (item.subItems?.some((subItem) => subItem.href === pathname))
        return item;
    }
  }
  return navs[0].items[0];
};

export const getCurrentTabById = (
  id: string,
  pathname: Route["pathname"],
): OnChangeTabItemProps => {
  for (const nav of navs) {
    for (const item of nav.items) {
      for (const subItem of item.subItems || []) {
        if (subItem.href === pathname) return subItem;
      }
      if (item.id === id) return item;
    }
  }
  return navs[0].items[0];
};
