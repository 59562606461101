import { Flex } from "@chakra-ui/react";
import React from "react";

interface Props {
  children: React.ReactNode;
}

const Container = ({ children }: Props) => {
  return (
    <Flex
      flexDirection="column"
      width="full"
      maxWidth="1440px"
      minWidth="full"
      position="relative"
      marginX="auto"
    >
      {children}
    </Flex>
  );
};

export default React.memo(Container);
