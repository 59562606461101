import {
  Flex,
  FlexProps,
  LightMode,
  Link,
  Skeleton,
  Tooltip,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

import config from "configs/app";
import useApiQuery from "lib/api/useApiQuery";
import { CHECKPOINTS, HOMEPAGE_STATS } from "stubs/stats";
import GasInfoTooltipContent from "ui/shared/GasInfoTooltipContent/GasInfoTooltipContent";
import IconSvg from "ui/shared/IconSvg";
import numbro from "numbro";

type Props = {
  //
} & FlexProps;
const TopBarStats = (props: Props) => {
  // have to implement controlled tooltip because of the issue - https://github.com/chakra-ui/chakra-ui/issues/7107
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();

  const handleClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onToggle();
    },
    [onToggle],
  );

  const { data, isPlaceholderData, isError } = useApiQuery("homepage_stats", {
    queryOptions: {
      placeholderData: HOMEPAGE_STATS,
      refetchOnMount: false,
    },
  });

  const { data: checkpoint } = useApiQuery("checkpoints", {
    queryOptions: {
      refetchOnMount: false,
      placeholderData: CHECKPOINTS,
    },
  });

  if (isError) {
    return <div style={{ flex: 1 }} />;
  }

  return (
    <Flex
      alignItems={{ base: "flex-start", lg: "center" }}
      textStyle="8125"
      gap={{ base: 1, lg: 4 }}
      flexDirection={{ base: "column", lg: "row" }}
      {...props}
    >
      {checkpoint && (
        <Skeleton isLoaded={!isPlaceholderData}>
          <chakra.span color="neutral.light.6">Checkpoint: </chakra.span>
          <chakra.span color="secondary.light">
            {numbro(checkpoint.tx_checkpoint).format("0,0")}{" "}
            {checkpoint.latest_block - checkpoint.tx_checkpoint < 200
              ? "🟢"
              : "🟠"}
          </chakra.span>
        </Skeleton>
      )}

      {data?.coin_price && (
        <Skeleton isLoaded={!isPlaceholderData}>
          <chakra.span color="neutral.light.6">
            {config.chain.governanceToken.symbol ||
              config.chain.currency.symbol}{" "}
            Price:{" "}
          </chakra.span>
          <chakra.span color="secondary.light">
            $
            {Number(data.coin_price).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 6,
            })}
          </chakra.span>
        </Skeleton>
      )}
      {/* {data?.coin_price && config.UI.homepage.showGasTracker && (
        <TextSeparator
          color="divider"
          display={{ base: "none", md: "block" }}
        />
      )} */}
      {data?.gas_prices &&
        data.gas_prices.average !== null &&
        config.UI.homepage.showGasTracker && (
          <Skeleton
            isLoaded={!isPlaceholderData}
            alignItems="center"
            display="flex"
          >
            <IconSvg name="gas" boxSize={3} />
            <chakra.span color="neutral.light.6">Gas:</chakra.span>
            <LightMode>
              <Tooltip
                label={<GasInfoTooltipContent gasPrices={data.gas_prices} />}
                hasArrow={false}
                borderRadius="md"
                offset={[0, 16]}
                bgColor="blackAlpha.900"
                p={0}
                isOpen={isOpen}
              >
                <Link
                  ml={1}
                  _hover={{ textDecoration: "none", color: "primary.light.3" }}
                  color="secondary.light"
                  onClick={handleClick}
                  onMouseEnter={onOpen}
                  onMouseLeave={onClose}
                >
                  {data.gas_prices.average} gusei
                </Link>
              </Tooltip>
            </LightMode>
          </Skeleton>
        )}
    </Flex>
  );
};

export default TopBarStats;
