import type { SearchResultItem } from "types/api/search";
import type { MarketplaceAppOverview } from "types/client/marketplace";

export type ApiCategory =
  | "token_cw"
  | "token_erc"
  | "nft_erc"
  | "nft_cw"
  | "address"
  | "public_tag"
  | "transaction"
  | "block";
export type Category = ApiCategory | "app";

export type ItemsCategoriesMap = Record<ApiCategory, Array<SearchResultItem>> &
  Record<"app", Array<MarketplaceAppOverview>>;

export type SearchResultAppItem = {
  type: "app";
  app: MarketplaceAppOverview;
};

export const searchCategories: Array<{ id: Category; title: string }> = [
  { id: "app", title: "Apps" },
  { id: "token_erc", title: "Tokens (ERC-20)" },
  { id: "token_cw", title: "Tokens (CW-20)" },
  { id: "nft_erc", title: "NFTs (ERC-721 & 1155)" },
  { id: "nft_cw", title: "NFTs (CW-721)" },
  { id: "address", title: "Addresses" },
  { id: "public_tag", title: "Public tags" },
  { id: "transaction", title: "Transactions" },
  { id: "block", title: "Blocks" },
];

export const searchItemTitles: Record<
  Category,
  { itemTitle: string; itemTitleShort: string }
> = {
  app: { itemTitle: "App", itemTitleShort: "App" },
  token_erc: { itemTitle: "Token", itemTitleShort: "Token" },
  token_cw: { itemTitle: "Token", itemTitleShort: "Token" },
  nft_erc: { itemTitle: "NFT", itemTitleShort: "NFT" },
  nft_cw: { itemTitle: "NFT", itemTitleShort: "NFT" },
  address: { itemTitle: "Address", itemTitleShort: "Address" },
  public_tag: { itemTitle: "Public tag", itemTitleShort: "Tag" },
  transaction: { itemTitle: "Transaction", itemTitleShort: "Txn" },
  block: { itemTitle: "Block", itemTitleShort: "Block" },
};

export function getItemCategory(
  item: SearchResultItem | SearchResultAppItem,
): Category | undefined {
  switch (item.type) {
    case "address":
    case "contract": {
      return "address";
    }
    case "token": {
      if (item.token_type === "ERC-20") {
        return "token_erc";
      }
      if (item.token_type === "CW-20") {
        return "token_cw";
      }
      if (item.token_type === "ERC-721" || item.token_type === "ERC-1155") {
        return "nft_erc";
      }
      if (item.token_type === "CW-721") {
        return "nft_cw";
      }
    }
    case "block": {
      return "block";
    }
    case "label": {
      return "public_tag";
    }
    case "transaction": {
      return "transaction";
    }
    case "app": {
      return "app";
    }
  }
}
