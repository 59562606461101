import {
  Center,
  CenterProps,
  LayoutProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import IconSvg from "./IconSvg";

type Props = {
  text?: React.ReactNode;
  boxSize?: LayoutProps["boxSize"];
} & Partial<CenterProps>;

const Empty = ({ text, boxSize, ...props }: Props) => {
  return (
    <Center {...props}>
      <VStack spacing={3}>
        <IconSvg src="/icons/empty.svg" boxSize={boxSize ?? "10rem"}></IconSvg>
        <Text color="neutral.light.8" textStyle="1125">
          {text ?? "No data found"}
        </Text>
      </VStack>
    </Center>
  );
};

export default Empty;
