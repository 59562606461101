import { As, chakra, forwardRef, TextProps } from "@chakra-ui/react";
import shortenString from "ui/shared/truncate/shortenString";
import { ForwardedRef } from "react";
import TruncatedTextTooltip from "./TruncatedTextTooltip";

type Props = {
  hash: string;
  isTooltipDisabled?: boolean;
  as?: As;
  headLength?: number;
  tailLength?: number;
  fontWeight?: string | number;
} & Partial<TextProps>;

const HashStringShorten = (
  {
    hash,
    isTooltipDisabled,
    as = "span",
    color,
    headLength,
    tailLength,
    fontWeight,
    ...props
  }: Props,
  ref?: ForwardedRef<HTMLSpanElement>,
) => {
  if (hash.length <= 8) {
    return <chakra.span as={as}>{hash}</chakra.span>;
  }

  return (
    <TruncatedTextTooltip
      defaultIsTruncated={true}
      label={hash}
      isDisabled={isTooltipDisabled}
    >
      <chakra.span
        ref={ref}
        as={as}
        color={color || "inherit"}
        fontWeight={fontWeight}
        {...props}
      >
        {shortenString(hash, headLength, tailLength)}
      </chakra.span>
    </TruncatedTextTooltip>
  );
};

export default forwardRef(HashStringShorten);
