import { Flex, FlexProps, Text } from "@chakra-ui/react";
import config from "configs/app";
import OptimizationImage from "ui/shared/OptimizationImage";

type Props = {
  //
} & Partial<FlexProps>;

const FooterProjectInfo = (props: Props) => {
  return (
    <Flex flexDirection="column" justifyContent="space-between" gap={4}>
      <Flex
        display="flex"
        flexDirection="column"
        color="neutral.light.1"
        width={{ base: "full", lg: "unset" }}
        flexWrap="wrap"
        {...props}
        gap={3}
      >
        <OptimizationImage
          hasWrapper
          wrapperProps={{ boxSize: 8 }}
          src="/icons/logo/footer-logo.svg"
          alt={`${config.chain.name} network logo`}
        />

        <Flex flexDirection="column" gap={1}>
          <Text
            width="full"
            color="neutral.light.1"
            textStyle="125"
            letterSpacing="-0.0125rem"
          >
            Powered by Sei Blockchain
          </Text>

          <Flex alignItems="center" width="full">
            <Text textStyle="1" width="full" color="neutral.light.1">
              Seitrace is a Block Explorer and Analytics Platform for Sei
              Network
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FooterProjectInfo;
