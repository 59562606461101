import type { Feature } from "./types";

import { getEnvValue, getChainKeyConfig } from "configs/app/utils";

const defaultApi = `${
  getEnvValue("NEXT_PUBLIC_APP_PROTOCOL") || "https"
}://${getEnvValue("NEXT_PUBLIC_APP_HOST")}:${getEnvValue(
  "NEXT_PUBLIC_APP_PORT",
)}`;
const apiEndpoint = defaultApi;

const title = "Blockchain statistics";

const config: Feature<{ api: { endpoint: string; basePath: string } }> =
  (() => {
    if (apiEndpoint) {
      return Object.freeze({
        title,
        isEnabled: true,
        api: {
          endpoint: apiEndpoint,
          basePath: getChainKeyConfig() + "-stats",
        },
      });
    }

    return Object.freeze({
      title,
      isEnabled: false,
    });
  })();

export default config;
