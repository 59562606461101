import React from "react";

import useFetchProfileInfo from "lib/hooks/useFetchProfileInfo";
import IconSvg from "ui/shared/IconSvg";
import OptimizationImage, { OptimizationImageProps } from "./OptimizationImage";

type Props = {
  //
} & Partial<OptimizationImageProps>;

const UserAvatar = ({ ...props }: Props) => {
  const { data } = useFetchProfileInfo();

  return (
    <OptimizationImage
      flexShrink={0}
      src={data?.picture || ""}
      alt="profile_avatar"
      hasWrapper
      objectFit="cover"
      transition="transform 0.2s ease-in-out"
      fallback={
        <IconSvg
          src="/icons/avatar.svg"
          boxSize="full"
          color="neutral.light.6"
        ></IconSvg>
      }
      {...props}
      wrapperProps={{
        boxSize: "2.5rem",
        borderWidth: "0.5px",
        borderRadius: "full",
        borderColor: "accent.orange",
        overflow: "hidden",
        transition: "transform 0.2s ease-in-out",
        ...props.wrapperProps,
      }}
    />
  );
};

export default React.memo(UserAvatar);
