import React, { ReactNode } from "react";
import type { AppProps } from "next/app";

import * as Sentry from "@sentry/react";
import type { ChakraProps } from "@chakra-ui/react";
import { ThemeProvider, BaseStyles } from "@primer/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import config from "configs/app";
import { AppContextProvider } from "lib/contexts/app";
import { ChakraProvider } from "lib/contexts/chakra";
import { SocketProvider } from "lib/socket/context";
import useQueryClientConfig from "lib/api/useQueryClientConfig";

import dynamic from "next/dynamic";
import theme from "theme";

import GoogleAnalytics from "ui/shared/GoogleAnalytics";
import AppErrorBoundary from "ui/shared/AppError/AppErrorBoundary";

import { LogtoProvider } from "@logto/react";

const ReduxProvider = dynamic(() => import("lib/contexts/ReduxContext"), {
  ssr: false,
});

type Props = Pick<AppProps, "pageProps"> & {
  children: ReactNode;
};

const ERROR_SCREEN_STYLES: ChakraProps = {
  h: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  width: "fit-content",
  maxW: "800px",
  margin: "0 auto",
  p: { base: 4, lg: 0 },
};

function InitContexts({ children, pageProps }: Props) {
  const queryClient = useQueryClientConfig();

  const handleError = React.useCallback((error: Error) => {
    Sentry.captureException(error);
  }, []);

  return (
    <AppErrorBoundary {...ERROR_SCREEN_STYLES} onError={handleError}>
      <ReduxProvider>
        <LogtoProvider config={config.app.authWorkspace}>
          <QueryClientProvider client={queryClient}>
            <AppContextProvider pageProps={pageProps}>
              <ChakraProvider theme={theme} cookies={pageProps.cookies}>
                <SocketProvider
                  url={`${config.api.socket}${config.api.basePath}/socket/v2`}
                >
                  <ThemeProvider>
                    <BaseStyles>{children}</BaseStyles>
                  </ThemeProvider>
                </SocketProvider>
                <ReactQueryDevtools
                  buttonPosition="bottom-left"
                  position="left"
                />
                <GoogleAnalytics />
              </ChakraProvider>
            </AppContextProvider>
          </QueryClientProvider>
        </LogtoProvider>
      </ReduxProvider>
    </AppErrorBoundary>
  );
}

export default InitContexts;
