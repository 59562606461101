import { useLogto } from "@logto/react";
import { useQuery } from "@tanstack/react-query";
import config from "configs/app";
import useApiQuery from "lib/api/useApiQuery";
import { useEffect, useState } from "react";
// import * as cookies from "lib/cookies";

export default function useFetchProfileInfo() {
  const { isAuthenticated, getAccessToken, isLoading, clearAllTokens } =
    useLogto();

  const [isLogged, setIsLogged] = useState<boolean | "loading">("loading");

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setIsLogged(isAuthenticated);
  }, [isLoading]);

  const { data: accessToken, isFetched: accessTokenIsFetched } = useQuery({
    queryKey: ["access_token"],
    queryFn: async () => {
      return await getAccessToken(config.app.authWorkspace.resources[0]).catch(
        (error) => {
          clearAllTokens();
          throw error;
        },
      );
    },
    enabled: isLogged === true,
    retry: false,
  });

  const { isFetched: isWorkspaceTokenIsFetched } = useApiQuery(
    "issue_workspace_token",
    {
      fetchParams: {
        method: "GET",
      },
      queryParams: {
        access_token: accessToken!,
      },
      queryOptions: {
        staleTime: Infinity,
        enabled: Boolean(isLogged === true && accessToken),
      },
    },
  );

  const { isFetched: myQuotaIsFetched } = useApiQuery("my_quota", {
    queryOptions: {
      staleTime: Infinity,
      enabled: Boolean(isLogged === true && accessToken),
    },
  });

  /**
   * @description auto create quota
   */

  const response = useApiQuery("workspace_userinfo", {
    queryOptions: {
      refetchOnMount: false,
      retryOnMount: false,
      enabled: isLogged === true,
    },
  });

  return {
    ...response,
    isLogged,
    isFetched:
      accessTokenIsFetched &&
      isLogged &&
      isWorkspaceTokenIsFetched &&
      myQuotaIsFetched &&
      response.isFetched,
  };
}
