import type { ToastProps, AlertStatus } from "@chakra-ui/react";
import {
  Alert,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";
import { chakra } from "@chakra-ui/system";
import React from "react";

function getBgColor(status?: AlertStatus) {
  switch (status) {
    case "success":
      return "secondary.02.bg";
    case "error":
      return "secondary.05.bg";
    case "warning":
      return "secondary.01.bg";
    case "info":
      return "secondary.04.bg";
    default:
      return "secondary.06.bg";
  }
}
function getBorderColor(status?: AlertStatus) {
  switch (status) {
    case "success":
      return "secondary.02";
    case "error":
      return "secondary.05";
    case "warning":
      return "secondary.01";
    case "info":
      return "secondary.04";
    default:
      return "secondary.06";
  }
}

function getColor(status?: AlertStatus) {
  switch (status) {
    case "success":
    case "success":
      return "secondary.02.text";
    case "error":
      return "secondary.05.text";
    case "warning":
      return "secondary.01.text";
    case "info":
      return "secondary.04.text";
    default:
      return "secondary.06.text";
  }
}

const Toast = ({
  onClose,
  title,
  description,
  id,
  isClosable,
  status,
}: ToastProps) => {
  const ids = id
    ? {
        root: `toast-${id}`,
        title: `toast-${id}-title`,
        description: `toast-${id}-description`,
      }
    : undefined;

  const bgColor = getBgColor(status);
  const color = getColor(status);
  const borderColor = getBorderColor(status);
  return (
    <Alert
      id={ids?.root}
      alignItems="start"
      borderRadius="md"
      boxShadow="lg"
      paddingY={4}
      paddingLeft={6}
      paddingRight="72px"
      bgColor={bgColor}
      textAlign="start"
      width="auto"
      maxWidth="400px"
      borderWidth="0.5px"
      borderColor={borderColor}
    >
      <chakra.div flex="1" maxWidth="100%">
        {title && (
          <AlertTitle color={color} id={ids?.title}>
            {title}
          </AlertTitle>
        )}
        {description && (
          <AlertDescription
            color="neutral.light.7"
            id={ids?.description}
            display="block"
          >
            {description}
          </AlertDescription>
        )}
      </chakra.div>
      {isClosable && (
        <CloseButton
          borderRadius="base"
          color="neutral.light.6"
          onClick={onClose}
          position="absolute"
          boxSize={5}
          insetEnd={4}
          top={4}
        />
      )}
    </Alert>
  );
};

export default Toast;
