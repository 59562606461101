import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type ProposalState = {
  proposalId: string;
  proposalRecentVoteCounter: { isLoading: boolean; count: number };
  proposalValidatorVoteCounter: { isLoading: boolean; count: number };
};

const initialState: ProposalState = {
  proposalId: "",
  proposalRecentVoteCounter: { isLoading: false, count: 0 },
  proposalValidatorVoteCounter: { isLoading: false, count: 0 },
};

export const proposalReducer = createSlice({
  name: "proposal",
  initialState,
  reducers: {
    changeProposalId: (state, data: PayloadAction<string>) => {
      return {
        ...state,
        proposalId: data.payload,
      };
    },
    changeProposalRecentVoteCounter: (
      state,
      data: PayloadAction<{
        isLoading: boolean;
        count: number;
      }>,
    ) => {
      return {
        ...state,
        proposalRecentVoteCounter: data.payload,
      };
    },
    changeProposalValidatorVoteCounter: (
      state,
      data: PayloadAction<{
        isLoading: boolean;
        count: number;
      }>,
    ) => {
      return {
        ...state,
        proposalValidatorVoteCounter: data.payload,
      };
    },
  },
});

export const {
  changeProposalId,
  changeProposalRecentVoteCounter,
  changeProposalValidatorVoteCounter,
} = proposalReducer.actions;
export default proposalReducer.reducer;
