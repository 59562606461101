import { Skeleton } from "@chakra-ui/react";
import { NFT_TOKEN_TYPES, TOKEN_TYPES } from "lib/token/tokenTypes";

import IconSvg, { IconSvgProps } from "ui/shared/IconSvg";

export const TokenLogoPlaceholder = ({
  type,
  isLoading,
  ...props
}: {
  type?: string;
} & Partial<IconSvgProps>) => {
  return (
    <Skeleton isLoaded={!isLoading} {...props}>
      <IconSvg
        backgroundColor="neutral.light.2"
        borderColor="neutral.light.4"
        borderWidth="0.5px"
        color="neutral.light.7"
        borderRadius="full"
        name={
          (NFT_TOKEN_TYPES.find((x) => x.id === type) && "nft_shield") ||
          (TOKEN_TYPES.find((x) => x.id === type) && "ft_shield") ||
          "token-placeholder"
        }
        boxSize={4}
        flexShrink={0}
        transitionProperty="background-color,color"
        transitionDuration="normal"
        transitionTimingFunction="ease"
        {...props}
      />
    </Skeleton>
  );
};

export default TokenLogoPlaceholder;
