import { chakra } from "@chakra-ui/react";
import React from "react";

type Props = {
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  href?: string;
  target?: string;
  children: React.ReactNode;
};

const SearchBarSuggestItemLink = ({
  onClick,
  href,
  target,
  children,
}: Props) => {
  return (
    <chakra.a
      py={3}
      px={4}
      display="flex"
      flexDir="column"
      rowGap={2}
      _hover={{
        backgroundColor: "primary.light.1",
      }}
      fontSize="sm"
      onClick={onClick}
      href={href}
      target={target}
    >
      {children}
    </chakra.a>
  );
};

export default SearchBarSuggestItemLink;
