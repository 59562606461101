import {
  Box,
  Center,
  chakra,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { useHeaderContext } from "lib/contexts/HeaderContext";
import getNavChains from "lib/hooks/useNavChains";
import { onChainChange } from "lib/redux/reducers/chain";
import { useAppDispatch, useAppSelector } from "lib/redux/store";
import numbro from "numbro";
import { FC, useMemo } from "react";
import useApiQuery from "../../../lib/api/useApiQuery";
import { CHECKPOINTS } from "../../../stubs/stats";
import TopBarStats from "./TopBarStats";
import useIsMobile from "lib/hooks/useIsMobile";

const TopBar: FC<{ homePage?: boolean }> = ({ homePage }) => {
  const dispatch = useAppDispatch();

  const chainKey = useAppSelector((state) => state.chain.chainKey);

  const chainNavItems = useMemo(() => getNavChains(chainKey), [chainKey]);

  const { searchElement } = useHeaderContext();
  const { data: checkpoint, isPlaceholderData } = useApiQuery("checkpoints", {
    queryOptions: {
      refetchOnMount: false,
      placeholderData: CHECKPOINTS,
    },
  });

  const isMobile = useIsMobile();

  return (
    <Box bgColor="neutral.light.1">
      <Flex
        maxWidth="full"
        paddingY={{ base: 2, lg: "0.375rem", xl: "0.5rem" }}
        gap={{ base: 3, lg: 4 }}
        flexWrap="wrap"
        alignItems="center"
        w={{ base: "100%", xl: "1440px" }}
        px={{ base: 4, lg: 5, xl: 10 }}
        mx="auto"
        height="3.25rem"
        bgColor="neutral.light.1"
      >
        <TopBarStats
          flex={1}
          order={1}
          width={{ base: "full", lg: "unset" }}
          float="left"
          display={{ base: "none", lg: "flex" }}
        />
        <Flex
          order={2}
          w={{ base: "full", md: "auto" }}
          float={{ base: "unset", md: "right" }}
          columnGap={4}
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {!homePage && !isMobile && searchElement}
          <Flex
            alignItems={"center"}
            width={{ base: "100%", md: "unset" }}
            justifyContent={"space-between"}
            display={{ base: "flex", md: "block" }}
            order={{ base: 1, lg: 2 }}
          >
            <Flex display={{ base: "flex", md: "none" }} fontSize={11}>
              {checkpoint && (
                <Skeleton isLoaded={!isPlaceholderData}>
                  <chakra.span color="neutral.light.6">
                    Checkpoint:{" "}
                  </chakra.span>
                  <chakra.span color="secondary.light">
                    {numbro(checkpoint.tx_checkpoint).format("0,0")}{" "}
                    {checkpoint.latest_block - checkpoint.tx_checkpoint < 200
                      ? "🟢"
                      : "🟠"}
                  </chakra.span>
                </Skeleton>
              )}
            </Flex>

            <Flex alignItems="center" float="left" position="relative">
              <Menu>
                <MenuButton
                  cursor="pointer"
                  order={{ base: 2, lg: 3 }}
                  width={{ base: "full", lg: "unset" }}
                >
                  <Flex
                    alignItems="center"
                    gap={2}
                    borderWidth="1px"
                    borderRadius="0.5rem"
                    height={9}
                    padding={2}
                  >
                    <Center boxSize={5}>
                      <Image src="/icons/top-chainselect.png" boxSize={5} />
                    </Center>
                    <Text fontSize="12px">
                      {chainNavItems.find((chain) => chain.isActive)?.text ||
                        ""}
                    </Text>
                  </Flex>
                </MenuButton>
                <MenuList zIndex={1000}>
                  {chainNavItems.map((item) => (
                    <MenuItem
                      key={`sub-item-${Math.random().toString()}`}
                      disabled={item.disabled}
                      cursor={item.disabled ? "not-allowed" : "pointer"}
                      _hover={{
                        bg: item.disabled
                          ? "transparent"
                          : "secondary.light.bg.red",
                      }}
                      onClick={() => {
                        if (!item.disabled) {
                          dispatch(
                            onChainChange({
                              chainKey: item.chainKey,
                              isReload: true,
                            }),
                          );
                        }
                      }}
                    >
                      <Text
                        color={
                          item.disabled
                            ? "#B9B3B3"
                            : item.isActive
                              ? "primary.light.3"
                              : "neutral.light.7"
                        }
                        _hover={{
                          color: item.disabled ? "#B9B3B3" : "primary.light.3",
                        }}
                        fontSize={{ base: "sm", lg: "md" }}
                      >
                        {item.text}
                      </Text>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TopBar;
