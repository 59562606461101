import {
  Button,
  Center,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftAddon,
  InputLeftAddonProps,
  InputProps,
  InputRightAddon,
  forwardRef,
} from "@chakra-ui/react";
import React from "react";
import IconSvg from "ui/shared/IconSvg";
import { newOnChangeEvent } from "ui/utils/dom";

export type SearchInputProps = {
  leftProps?: Partial<InputLeftAddonProps>;
  groupProps?: Partial<InputGroupProps>;
} & InputProps;

const SearchInput = (
  { groupProps, ...props }: SearchInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) => {
  return (
    <InputGroup
      ref={ref}
      padding={2}
      height={9}
      borderWidth="1px"
      backgroundColor="neutral.light.1"
      borderColor="neutral.light.4"
      borderRadius="0.5rem"
      gap={3}
      transition="border-color 0.3s ease-in-out, color 0.3s ease-in-out"
      _hover={{
        borderColor: "neutral.light.5",
      }}
      role="group"
      {...groupProps}
    >
      <InputLeftAddon
        padding={0}
        height="full"
        border={0}
        background="unset"
        backgroundColor="unset"
      >
        <Center boxSize={5} padding={0}>
          <IconSvg
            name="search"
            color="neutral.light.5"
            _groupHover={{ color: "neutral.light.6" }}
            transition="all 0.3s ease-in-out"
            boxSize={4}
          />
        </Center>
      </InputLeftAddon>
      <Input
        variant="unstyled"
        height="full"
        padding={0}
        color="neutral.light.7"
        placeholder="Search here"
        fontSize="0.875rem"
        fontWeight={400}
        lineHeight="1.25rem"
        _placeholderShown={{
          color: "neutral.light.5",
          textOverflow: "ellipsis",
        }}
        _groupHover={{ color: "neutral.light.8" }}
        _placeholder={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          color: "neutral.light.5",
          textStyle: "875",
        }}
        ref={ref}
        {...props}
      />
      {props.value && (
        <InputRightAddon
          padding={0}
          height="full"
          border={0}
          background="unset"
          backgroundColor="unset"
        >
          <Center
            as={Button}
            variant="unstyled"
            onClick={() => {
              const event = newOnChangeEvent("");
              props.onChange?.(event);
            }}
            boxSize={5}
            padding={0}
            borderRadius="full"
          >
            <IconSvg name="clear" color="secondary.gray" boxSize={4} />
          </Center>
        </InputRightAddon>
      )}
    </InputGroup>
  );
};

export default forwardRef(SearchInput);
