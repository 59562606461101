import React, { createContext, ReactNode, useContext, useState } from "react";
import SearchBarTop from "ui/snippets/searchBar/SearchBarTop";

type Props = {
  children: ReactNode;
};

type ContextProps = {
  searchElement: ReactNode;
};

const Context = createContext<ContextProps>({
  searchElement: <></>,
});
const HeaderProvider = ({ children }: Props) => {
  const [searchElement] = useState(
    <SearchBarTop
      isFullscreen
      searchInputProps={{
        placeholder:
          "Search by Address / Txn Hash / Block / Token  / NFT collection",
      }}
    />,
  );
  return (
    <Context.Provider value={{ searchElement: searchElement }}>
      {children}
    </Context.Provider>
  );
};

export const useHeaderContext = () => useContext(Context);

export default HeaderProvider;
