const sizes = {
  sm: {
    field: {
      px: "0",
      height: "36px",
    },
  },
  md: {
    field: {
      px: "0",
      height: "56px",
    },
  },
  lg: {
    field: {
      px: "0",
      height: "76px",
    },
  },
};

const FancySelect = {
  sizes,
};

export default FancySelect;
