import type {
  NFTCointransferTypeCosmos,
  NFTTokenType,
  TokenType,
} from "types/api/token";

export type NftTypeInterface = {
  title: string;
  id: NFTTokenType;
};

export type TokenTypeInterface = {
  title: string;
  id: TokenType;
};

export const NFT_TOKEN_TYPES_EVM: Array<NftTypeInterface> = [
  { title: "ERC-721", id: "ERC-721" },
  { title: "ERC-1155", id: "ERC-1155" },
];

export const NFT_TOKEN_TYPES_COSMOS: Array<{
  title: string;
  id: NFTTokenType;
}> = [{ title: "CW-721", id: "CW-721" }];
export const NFT_IBC_TYPES_COSMOS: Array<{
  title: string;
  id: NFTCointransferTypeCosmos;
}> = [{ title: "Native tokens", id: "coin_transfer" }];

export const NFT_TOKEN_TYPES: Array<NftTypeInterface> = [
  ...NFT_TOKEN_TYPES_EVM,
  ...NFT_TOKEN_TYPES_COSMOS,
];

export const TOKEN_TYPES_EVM: Array<TokenTypeInterface> = [
  { title: "ERC-20", id: "ERC-20" },
];

export const TOKEN_TYPES_COSMOS: Array<TokenTypeInterface> = [
  { title: "CW-20", id: "CW-20" },
];

export const TOKEN_TYPES: Array<TokenTypeInterface> = [
  ...TOKEN_TYPES_EVM,
  ...TOKEN_TYPES_COSMOS,
  ...NFT_IBC_TYPES_COSMOS,
  // ...NFT_TOKEN_TYPES,
];

export const EVM_TOKEN_TYPES: Array<TokenTypeInterface> = [
  { title: "ERC-20", id: "ERC-20" },
  ...NFT_TOKEN_TYPES_EVM,
];

export const NATIVE_SEI_TOKEN_TYPES: Array<TokenTypeInterface> = [
  { title: "CW-20", id: "CW-20" },
  ...NFT_TOKEN_TYPES_COSMOS,
];

export const ALL_SEI_TOKEN_TYPES: Array<TokenTypeInterface> = [
  ...NATIVE_SEI_TOKEN_TYPES,
  ...NFT_IBC_TYPES_COSMOS,
];

export const TOKEN_TYPES_WITH_NFT = [...TOKEN_TYPES, ...NFT_TOKEN_TYPES];

export const NFT_TOKEN_TYPE_IDS = NFT_TOKEN_TYPES.map((i) => i.id);

export const TOKEN_TYPE_IDS = TOKEN_TYPES.map((i) => i.id);

export const ALL_TOKEN_TYPES = [...TOKEN_TYPES_WITH_NFT];
export const ALL_TOKEN_TYPE_IDS = ALL_TOKEN_TYPES.map((i) => i.id);
