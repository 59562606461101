import {
  As,
  chakra,
  ChakraProps,
  Skeleton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import _ from "lodash";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ContentBaseProps } from "../entities/base/components";
import { calculateString } from "./hashStringFormat";
import shortenString from "./shortenString";

type Props = {
  hash: string;
  isTooltipDisabled?: boolean;
  tailLength?: number;
  headLength?: number;
  justify?: "center" | "between";
  as?: As;
  entityRef?: React.MutableRefObject<HTMLElement | null>;
} & Partial<ChakraProps> &
  Pick<ContentBaseProps, "contentBoxRef" | "isLoading">;

const HashStringShortenDynamic = (
  {
    hash,
    isTooltipDisabled,
    tailLength,
    headLength,
    as = "span",
    entityRef,
    contentBoxRef,
    isLoading,
    ...props
  }: Props,
  ref?: React.ForwardedRef<HTMLDivElement>,
) => {
  if (isLoading) {
    return (
      <Skeleton>
        <span>{hash}</span>
      </Skeleton>
    );
  }
  return (
    <Tooltip
      ref={ref}
      label={hash}
      isDisabled={isTooltipDisabled}
      width="full"
      flex={1}
    >
      <>
        {hash.includes("...") ? (
          <Text color="inherit" {...props}>
            {hash}
          </Text>
        ) : hash === "N/A" ? (
          <Text color="neutral.light.7" {...props}>
            {hash}
          </Text>
        ) : (
          (() => {
            const elementRef = useRef<HTMLSpanElement>(null);
            const isCut = useMemo(
              () =>
                typeof headLength === "number" &&
                typeof tailLength === "number",
              [headLength, tailLength],
            );
            const [displayText, setDisplayText] = useState(
              isCut ? shortenString(hash, headLength, tailLength) : hash,
            );
            const isHead = useMemo(
              () =>
                typeof headLength === "number" &&
                typeof tailLength === "number",
              [headLength, tailLength],
            );

            const isTail = useMemo(
              () =>
                typeof headLength !== "number" &&
                typeof tailLength === "number",
              [headLength, tailLength],
            );

            const isDynamic = useMemo(
              () =>
                typeof headLength !== "number" &&
                typeof tailLength !== "number",
              [headLength, tailLength],
            );
            // const isNothing = !isHead && !isTail && !isCut;
            const firstPart = useMemo(
              () =>
                hash.substring(
                  0,
                  (isCut && headLength) ||
                    (isHead && headLength) ||
                    (isTail && hash.length - tailLength!) ||
                    Math.floor(hash.length / 2),
                ),
              [headLength, tailLength, hash],
            );
            const secondPart = useMemo(
              () =>
                hash.substring(
                  (isCut && hash.length - tailLength!) ||
                    (isHead && hash.length - headLength! + 1) ||
                    (isTail && hash.length - tailLength!) ||
                    Math.ceil(hash.length / 2),
                ),
              [headLength, tailLength, hash],
            );

            const resizeHandler = useCallback(
              _.debounce(() => {
                if (elementRef.current) {
                  elementRef.current.innerText = hash;
                  elementRef.current.style.textOverflow = "ellipsis";
                }

                const text = calculateString({
                  firstPart,
                  secondPart,
                  hash,
                  elementRef,
                  isDynamic,
                  isCut,
                  isHead,
                  isTail,
                  tailLength,
                  entityRef,
                  contentBoxRef,
                })!;
                if (elementRef.current) {
                  elementRef.current.style.textOverflow = "clip";
                  elementRef.current.innerText = text;
                }
                setDisplayText(text);
              }, 50),
              [],
            );

            useEffect(() => {
              resizeHandler();
            }, [elementRef.current, isDynamic, isCut, isHead, isTail]);

            useEffect(() => {
              const resizeObserver = new ResizeObserver(resizeHandler);

              resizeObserver.observe(document.body);
              return function cleanup() {
                resizeObserver.unobserve(document.body);
              };
            }, []);

            return (
              // <Skeleton isLoaded={displayText !== "placeholder"}>
              <chakra.span
                noOfLines={1}
                ref={elementRef}
                as={as}
                flex={1}
                display="flex"
                width="full"
                position="relative"
                {...props}
                overflow="unset"
                textOverflow="unset"
                whiteSpace="normal"
                lineHeight={props.lineHeight || "1.25rem"}
                height={props.lineHeight || "1.25rem"}
                // data-font-size={fontSize || "N/A"}
                data-first={firstPart}
                data-second={secondPart}
                data-hash={hash}
                data-dynamic={isDynamic}
                data-cut={isCut}
                // data-count={renderCount.current}
                data-head={headLength}
                data-tail={tailLength}
              >
                {displayText}
              </chakra.span>
              // </Skeleton>
            );
          })()
        )}
      </>
    </Tooltip>
  );
};

export default forwardRef(HashStringShortenDynamic);
