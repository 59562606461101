const borders = {
  radii: {
    none: "0",
    sm: "4px",
    base: "8px",
    md: "12px",
    lg: "16px",
    xl: "24px",
    full: "9999px",
  },
  "1px": "1px solid red",
  "0.5px": "0.5px solid red",
};

export default borders;
