import { ChainKey } from "configs/app/chainConfigs";
import * as cookies from "lib/cookies";

export const getCookieChainKey = (): ChainKey => {
  try {
    return (cookies.get(cookies.NAMES.CHAIN_KEY) ||
      ChainKey.PACIFIC_1) as ChainKey;
  } catch (err) {
    return ChainKey.PACIFIC_1;
  }
};

export const setCookieChainKey = (chainKey: string | undefined) => {
  try {
    cookies.set(cookies.NAMES.CHAIN_KEY, chainKey || "");
    return true;
  } catch (err) {
    console.warn(err);
  }
};
