import { Skeleton, Tag as ChakraTag } from "@chakra-ui/react";
import type { TagProps } from "@chakra-ui/react";
import React from "react";

import TruncatedTextTooltip from "ui/shared/truncate/TruncatedTextTooltip";

interface Props extends TagProps {
  isLoading?: boolean;
  tagProps?: Partial<TagProps>;
}

const Tag = (
  { isLoading, tagProps, ...props }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
) => {
  if (props.isTruncated && typeof props.children === "string") {
    if (!props.children) {
      return null;
    }

    return (
      <Skeleton
        isLoaded={!isLoading}
        display="inline-block"
        borderRadius="sm"
        maxW="100%"
      >
        <TruncatedTextTooltip label={props.children}>
          <ChakraTag
            colorScheme="gray"
            textStyle="875"
            variant="subtle"
            {...props}
            ref={ref}
          />
        </TruncatedTextTooltip>
      </Skeleton>
    );
  }
  return (
    <Skeleton
      isLoaded={!isLoading}
      display="inline-block"
      borderRadius="sm"
      maxW="100%"
      {...props}
    >
      <ChakraTag
        textStyle="875"
        colorScheme="gray"
        variant="subtle"
        {...props}
        {...tagProps}
        ref={ref}
      />
    </Skeleton>
  );
};

export default React.forwardRef(Tag);
