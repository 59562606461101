import { Box, BoxProps, Image } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import React from "react";

import config from "configs/app";
import IdenticonGithub from "ui/shared/IdenticonGithub";
import stringToHex from "../../../../lib/stringToHex";

type IconProps = {
  hash: string;
  size: number;
  color?: string;
} & Partial<BoxProps>;

const Icon = dynamic(
  async () => {
    switch (config.UI.views.address.identiconType) {
      case "github": {
        return (props: IconProps) => <IdenticonGithub {...props} />;
      }

      case "blockie": {
        const { blo } = await import("blo");
        return (props: IconProps) => {
          const data = blo(
            stringToHex(props.hash) as `0x${string}`,
            props.size,
          );
          return (
            <Image src={data} alt={`Identicon for ${props.hash}}`} {...props} />
          );
        };
      }

      case "nice-avatar": {
        const NiceAvatar = await import("react-nice-avatar");
        return (props: IconProps) => {
          const config = NiceAvatar.genConfig(props.hash.toLowerCase());
          return (
            <div>
              <NiceAvatar.default
                style={{
                  width: `${props.width}`,
                  height: `${props.height}`,
                }}
                {...config}
              />
            </div>
          );
        };
      }

      case "jazzicon": {
        const Jazzicon = await import("react-jazzicon");
        return (props: IconProps) => {
          return (
            <Jazzicon.default
              paperStyles={{ ...props }}
              seed={Jazzicon.jsNumberForAddress(stringToHex(props.hash))}
            />
          );
        };
      }

      case "gradient_avatar": {
        const GradientAvatar = (await import("gradient-avatar")).default;
        return (props: IconProps) => {
          const svg = GradientAvatar(props.hash, props.size);
          return <Box dangerouslySetInnerHTML={{ __html: svg }} {...props} />;
        };
      }

      default: {
        return () => null;
      }
    }
  },
  {
    ssr: false,
  },
);

type Props = IconProps;

const AddressIdenticon = ({ size, hash, ...props }: Props) => {
  return (
    <Box boxSize={`${size}px`} borderRadius="full" overflow="hidden" {...props}>
      <Icon size={size} hash={hash} {...props} />
    </Box>
  );
};

export default React.memo(AddressIdenticon);
