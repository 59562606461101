import { StorageProvider } from "./storage.provider";

export * from "./constants";

let storageProvider: StorageProvider;

export const getStorageProvider = () => {
  if (storageProvider) return storageProvider;
  storageProvider = new StorageProvider("SEITRACE", window.localStorage);
  return storageProvider;
};
