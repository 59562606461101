import { Flex, chakra } from "@chakra-ui/react";
import React from "react";

interface Props {
  className?: string;
  children: React.ReactNode;
}

const Content = ({ children, className }: Props) => {
  return (
    <Flex
      flexDirection="column"
      width="full"
      as="main"
      className={className}
      minHeight={{
        base: "calc(100dvh - 61px - 1px - 52px - 31.3125rem)",
        lg: "calc(100dvh - 61px - 1px - 52px - 16.8125rem)",
      }}
    >
      {children}
    </Flex>
  );
};

export default React.memo(chakra(Content));
