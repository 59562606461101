import { Flex } from "@chakra-ui/react";
import React from "react";

import OptimizationImage from "ui/shared/OptimizationImage";
import FooterMoreInfo from "./FooterMoreInfo";
import FooterProjectInfo from "./FooterProjectInfo";
import { footerHyperlinkGroupList } from "./types";
import FooterLinkGroup from "./FooterLinkGroup";

const Footer = () => {
  return (
    <Flex
      as="footer"
      width="full"
      mx="auto"
      height={{ base: "31.3125rem", lg: "16.8125rem" }}
      position="relative"
    >
      <OptimizationImage
        hasWrapper
        wrapperProps={{ width: "full", height: "full" }}
        alt="footer"
        src="/images/footer.jpeg"
        objectPosition="bottom left"
        background="linear-gradient(135deg, #2d294d, #c12f26)"
      ></OptimizationImage>
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        inset={0}
        position="absolute"
        width={{ base: "full", xl: "1440px" }}
        mx="auto"
        paddingTop={10}
        paddingX={{ base: 4, lg: 5, "2lg": 8, xl: 10 }}
        paddingBottom={{ base: 3, lg: 2 }}
        gap={10}
      >
        <Flex
          height="full"
          justifyContent={{ lg: "space-between" }}
          alignItems="stretch"
          flexDirection={{ base: "column", lg: "row" }}
          gap={10}
        >
          <FooterProjectInfo></FooterProjectInfo>
          <Flex
            gap={{ base: "1rem", lg: "7.5rem" }}
            justifyContent="space-between"
          >
            {footerHyperlinkGroupList.map(({ title, items }) => (
              <FooterLinkGroup
                key={title}
                title={title}
                items={items}
              ></FooterLinkGroup>
            ))}
          </Flex>
        </Flex>
        <FooterMoreInfo></FooterMoreInfo>
      </Flex>
    </Flex>
  );
};

export default React.memo(Footer);
