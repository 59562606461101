import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import IconSvg from "ui/shared/IconSvg";
import { ProfileSideBarItem } from "../types";

type Props = {
  isLoading?: boolean;
} & Partial<ButtonProps> &
  Pick<ProfileSideBarItem, "icon" | "title" | "isDisabled" | "isComingSoon">;

const ProfileNavButton = ({
  isLoading,
  isActive,
  onClick,
  isDisabled,
  icon,
  title,
  isComingSoon,
  ...props
}: Props) => {
  return (
    <Skeleton isLoaded={!isLoading} borderRadius="0.25rem">
      <Button
        width="full"
        variant="sidebar"
        justifyContent="flex-start"
        isLoading={isLoading}
        paddingX={{ base: 2, lg: 3 }}
        paddingY={2}
        columnGap={3}
        alignItems="center"
        isActive={isActive}
        onClick={onClick}
        isDisabled={isDisabled}
        {...props}
      >
        {icon && (
          <IconSvg
            boxSize={5}
            color="inherit"
            flexShrink={0}
            _hover={{ color: "inherit" }}
            name={icon}
          ></IconSvg>
        )}
        <Flex
          alignItems="center"
          gap={1}
          flex={1}
          justifyContent="space-between"
        >
          <Text textStyle="1" color="inherit">
            {title}
          </Text>
          {isComingSoon && (
            <Box
              borderColor="primary.light.2"
              bgColor="primary.light.1"
              color="primary.light.4"
              borderWidth="0.5px"
              borderRadius="0.125rem"
              paddingX={1}
              paddingY={0}
              textStyle="625"
            >
              Coming soon
            </Box>
          )}
        </Flex>
      </Button>
    </Skeleton>
  );
};

export default ProfileNavButton;
