import { useAppSelector } from "lib/redux/store";
import { useMemo } from "react";
import getNavChains from "./useNavChains";

export const useCurrentChain = () => {
  const chainKey = useAppSelector((state) => state.chain.chainKey);

  const currentChainConfig = useMemo(
    () => getNavChains(chainKey).find((chain) => chain.chainKey === chainKey),
    [chainKey],
  );
  return currentChainConfig;
};
