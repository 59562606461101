import stripTrailingSlash from "lib/stripTrailingSlash";
import DevEnv from "../../configs/envs/config.dev.json";
import LocalDevEnv from "../../configs/envs/config.local.json";
import ProdEnv from "../../configs/envs/config.prod.json";

import { getChainKeyConfig, getEnvValue } from "./utils";

let apiHost, apiPort;
const env = getEnvValue("APP_ENV");

const chainKey = getChainKeyConfig();
console.log(chainKey);

if (env && env.includes("local")) {
  apiHost = (LocalDevEnv as any)[chainKey]["API_HOST"];
  apiPort = (LocalDevEnv as any)[chainKey]["API_PORT"];
} else if (env && env.includes("dev")) {
  apiHost = (DevEnv as any)[chainKey]["API_HOST"];
  apiPort = (DevEnv as any)[chainKey]["API_PORT"];
} else {
  apiHost = (ProdEnv as any)[chainKey]["API_HOST"];
  apiPort = (ProdEnv as any)[chainKey]["API_PORT"];
}

const appHost = getEnvValue("NEXT_PUBLIC_APP_HOST");
const appSchema = getEnvValue("NEXT_PUBLIC_APP_PROTOCOL") || "https";
const appPort = getEnvValue("NEXT_PUBLIC_APP_PORT");

const apiEndpoint = [
  appSchema || "https",
  "://",
  appHost,
  appPort && ":" + appPort,
]
  .filter(Boolean)
  .join("");

const socketSchema = getEnvValue("NEXT_PUBLIC_API_WEBSOCKET_PROTOCOL") || "wss";
const socketEndpoint = [socketSchema, "://", apiHost, apiPort && ":" + apiPort]
  .filter(Boolean)
  .join("");

const api = Object.freeze({
  host: appHost,
  protocol: appSchema,
  port: appPort,
  endpoint: apiEndpoint,
  socket: socketEndpoint,
  basePath: stripTrailingSlash(getEnvValue("NEXT_PUBLIC_API_BASE_PATH") || ""),
});

export default api;
