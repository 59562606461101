import { Flex, Box, VStack, useColorModeValue } from "@chakra-ui/react";
import React from "react";

import config from "configs/app";
import { useAppContext } from "lib/contexts/app";
import * as cookies from "lib/cookies";
import useHasAccount from "lib/hooks/useHasAccount";
import useNavItems, { isGroupItem } from "lib/hooks/useNavItems";
import getDefaultTransitionProps from "theme/utils/getDefaultTransitionProps";
import IconSvg from "ui/shared/IconSvg";
import NetworkLogo from "ui/snippets/networkMenu/NetworkLogo";
import NetworkMenu from "ui/snippets/networkMenu/NetworkMenu";

import NavLink from "./NavLink";
import NavLinkGroupDesktop from "./NavLinkGroupDesktop";

const NavigationDesktop = () => {
  const appProps = useAppContext();
  const cookiesString = appProps.cookies;

  const isNavBarCollapsedCookie = cookies.get(
    cookies.NAMES.NAV_BAR_COLLAPSED,
    cookiesString,
  );
  let isNavBarCollapsed;
  if (isNavBarCollapsedCookie === "true") {
    isNavBarCollapsed = true;
  }
  if (isNavBarCollapsedCookie === "false") {
    isNavBarCollapsed = false;
  }

  const { mainNavItems, accountNavItems } = useNavItems();

  const hasAccount = useHasAccount();

  const [isCollapsed, setCollapsedState] = React.useState<boolean | undefined>(
    isNavBarCollapsed,
  );

  const handleTogglerClick = React.useCallback(() => {
    setCollapsedState((flag) => !flag);
    cookies.set(
      cookies.NAMES.NAV_BAR_COLLAPSED,
      isCollapsed ? "false" : "true",
    );
  }, [isCollapsed]);

  const chevronIconStyles = {
    bgColor: useColorModeValue("white", "black"),
    color: useColorModeValue("blackAlpha.400", "whiteAlpha.400"),
    borderColor: "divider",
  };

  const isExpanded = isCollapsed === false;
  return (
    <Flex
      display={{ base: "none", lg: "flex" }}
      position="relative"
      flexDirection="column"
      alignItems="stretch"
      borderRight="1px solid"
      borderColor="divider"
      px={{ lg: isExpanded ? 6 : 4, xl: isCollapsed ? 4 : 6 }}
      py={12}
      width={{
        lg: isExpanded ? "229px" : "92px",
        xl: isCollapsed ? "92px" : "229px",
      }}
      {...getDefaultTransitionProps({ transitionProperty: "width, padding" })}
      sx={{
        "&:hover #expand-icon": {
          display: "block",
        },
      }}
    >
      {config.chain.isTestnet && (
        <IconSvg
          name="testnet"
          h="14px"
          w="49px"
          color="red.400"
          position="absolute"
          pl={3}
          top="34px"
        />
      )}
      <Box
        as="header"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="row"
        w="100%"
        pl={{ lg: isExpanded ? 3 : "15px", xl: isCollapsed ? "15px" : 3 }}
        pr={{ lg: isExpanded ? 0 : "15px", xl: isCollapsed ? "15px" : 0 }}
        h={10}
        transitionProperty="padding"
        transitionDuration="normal"
        transitionTimingFunction="ease"
      >
        <NetworkLogo isCollapsed={isCollapsed} />
        {Boolean(config.UI.sidebar.featuredNetworks) && (
          <NetworkMenu isCollapsed={isCollapsed} />
        )}
      </Box>
      <Box as="nav" mt={8} w="100%">
        <VStack as="ul" spacing="1" alignItems="flex-start">
          {mainNavItems.map((item) => {
            if (isGroupItem(item)) {
              return (
                <NavLinkGroupDesktop
                  key={item.text}
                  item={item}
                  isCollapsed={isCollapsed}
                />
              );
            } else {
              return (
                <NavLink
                  key={item.text}
                  item={item}
                  isCollapsed={isCollapsed}
                />
              );
            }
          })}
        </VStack>
      </Box>
      {hasAccount && (
        <Box
          as="nav"
          borderTopWidth="1px"
          borderColor="divider"
          w="100%"
          mt={6}
          pt={6}
        >
          <VStack as="ul" spacing="1" alignItems="flex-start">
            {accountNavItems.map((item) => (
              <NavLink key={item.text} item={item} isCollapsed={isCollapsed} />
            ))}
          </VStack>
        </Box>
      )}
      <IconSvg
        name="arrows/east-mini"
        width={6}
        height={6}
        border="1px"
        _hover={{ color: "primary.light.3" }}
        borderRadius="base"
        {...chevronIconStyles}
        transform={{
          lg: isExpanded ? "rotate(0)" : "rotate(180deg)",
          xl: isCollapsed ? "rotate(180deg)" : "rotate(0)",
        }}
        {...getDefaultTransitionProps({
          transitionProperty: "transform, left",
        })}
        transformOrigin="center"
        position="absolute"
        top="104px"
        left={{
          lg: isExpanded ? "216px" : "80px",
          xl: isCollapsed ? "80px" : "216px",
        }}
        cursor="pointer"
        onClick={handleTogglerClick}
        aria-label="Expand/Collapse menu"
        id="expand-icon"
        display="none"
      />
    </Flex>
  );
};

export default NavigationDesktop;
