import {
  Button,
  IconButton,
  IconButtonProps,
  Skeleton,
  useDisclosure,
} from "@chakra-ui/react";
import { memo, useCallback } from "react";

import { useLogto } from "@logto/react";

import config from "configs/app";
import useFetchProfileInfo from "lib/hooks/useFetchProfileInfo";
import {
  PopoverModal,
  PopoverModalContent,
  PopoverModalTrigger,
} from "ui/shared/PopoverModal";
import UserAvatar from "ui/shared/UserAvatar";
import ProfileMenuContent from "./ProfileMenuContent";

type Props = {
  onClick?: IconButtonProps["onClick"];
};

const ProfileMenu = ({ onClick }: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isAuthenticated, signIn } = useLogto();
  const { data: account, isLoading } = useFetchProfileInfo();

  const handleSignInClick = useCallback(() => {
    signIn(config.app.authWorkspace.redirectUrl);
  }, [signIn, account, isAuthenticated]);

  return (
    <PopoverModal
      isOpen={isOpen}
      onClose={onClose}
      isFullscreen={false}
      openDirection="zoom"
      borderRadius="full"
    >
      <PopoverModalTrigger borderRadius="full">
        {isLoading ? (
          <Skeleton
            flexShrink={0}
            boxSize="2.25rem"
            borderRadius="full"
          ></Skeleton>
        ) : account ? (
          <IconButton
            aria-label="profile menu"
            icon={<UserAvatar boxSize="2.25rem" />}
            variant="outline"
            colorScheme="blue"
            boxSize="2.25rem"
            padding={0}
            flexShrink={0}
            borderRadius="full"
            borderWidth="0.5px"
            borderColor="accent.orange"
            overflow="hidden"
            onClick={(e) => {
              onOpen();
              onClick?.(e);
            }}
          />
        ) : (
          <Button
            flexShrink={0}
            fontSize="sm"
            variant="solid"
            width="6.25rem"
            height="2.25rem"
            loadingText="Signing in..."
            isLoading={isLoading}
            onClick={handleSignInClick}
          >
            Sign In
          </Button>
        )}
      </PopoverModalTrigger>
      <PopoverModalContent
        right={{ base: "-20px", lg: "0px" }}
        top={{ base: "calc(100% + 5px)", lg: "cacl(100% + 10px)" }}
        width="17.5rem"
        padding="2rem 0.75rem 0.75rem 0.75rem"
        overflow="hidden"
      >
        {account && (
          <ProfileMenuContent
            data={account}
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
          />
        )}
      </PopoverModalContent>
    </PopoverModal>
  );
};

export default memo(ProfileMenu);
