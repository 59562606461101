import { useQueryClient } from "@tanstack/react-query";
import _pickBy from "lodash/pickBy";
import React from "react";

import * as cookies from "lib/cookies";
import type { CsrfData } from "types/client/account";

import config from "configs/app";
import isBodyAllowed from "lib/api/isBodyAllowed";
import isNeedProxy from "lib/api/isNeedProxy";
import { getResourceKey } from "lib/api/useApiQuery";
import type { Params as FetchParams } from "lib/hooks/useFetch";
import useFetch from "lib/hooks/useFetch";

import { useLogto } from "@logto/react";
import { AxiosRequestConfig } from "axios";
import { authEmitter } from "lib/events/authEmitter";
import buildUrl from "./buildUrl";
import type {
  ApiResource,
  ResourceName,
  ResourcePathParams,
  ResourcePayload,
} from "./resources";
import { RESOURCES } from "./resources";

export interface Params<R extends ResourceName> {
  pathParams?: ResourcePathParams<R>;
  queryParams?: Record<string, string | Array<string> | number | undefined>;
  fetchParams?: Pick<FetchParams, "body" | "method" | "signal">;
  configs?: AxiosRequestConfig;
}

export default function useApiFetch() {
  const fetch = useFetch();
  const queryClient = useQueryClient();
  const { token: csrfToken } =
    queryClient.getQueryData<CsrfData>(getResourceKey("csrf")) || {};

  const { getAccessToken } = useLogto();

  return React.useCallback(
    async <
      R extends ResourceName,
      SuccessType = ResourcePayload<R>,
      ErrorType = unknown,
    >(
      resourceName: R,
      { pathParams, queryParams, fetchParams, configs }: Params<R> = {},
    ) => {
      const resource: ApiResource = RESOURCES[resourceName];
      const withBody = isBodyAllowed(fetchParams?.method);

      const accessToken = await (async () => {
        return resource.endpoint && resource.needAuth
          ? (await getAccessToken(config.app.authWorkspace.resources[0])) &&
              cookies.get(cookies.NAMES.API_TOKEN)
          : resource.needWorkspaceAuth
            ? `Bearer ${await getAccessToken(
                config.app.authWorkspace.resources[0],
              )}`
            : undefined;
      })().catch((error) => {
        authEmitter.emit("AUTH::INVALID", error);
        throw error;
      });

      const headers = _pickBy(
        {
          "x-endpoint":
            resource.endpoint && isNeedProxy() ? resource.endpoint : undefined,
          Authorization: accessToken,
          "x-csrf-token": withBody && csrfToken ? csrfToken : undefined,
        },
        Boolean,
      ) as AxiosRequestConfig["headers"];

      let url = buildUrl(resourceName, pathParams, queryParams);
      if (isNeedProxy()) {
        url = url.replace("https://seitrace.com:3000/", "/");
      }

      return fetch<SuccessType, ErrorType>(
        url,
        {
          credentials: config.features.account.isEnabled
            ? "include"
            : "same-origin",
          headers,
          ...fetchParams,
        },
        {
          resource: resource.path,
        },
        configs,
      );
    },
    [fetch, csrfToken],
  );
}
