import { Flex, UseDisclosureReturn, VStack } from "@chakra-ui/react";
import config from "configs/app";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { WorkspaceUserInfo } from "types/api/account";
import ProfileAvatar from "./components/ProfileAvatar";
import ProfileDivider from "./components/ProfileDivider";
import ProfileNavButton from "./components/ProfileNavButton";
import ProfileSignOutButton from "./components/ProfileSignOutGroup";
import { useProfileSideBarNavs } from "./hooks/useProfileSideBarNavs";
import { getActiveTabByPathname } from "./utils";

const feature = config.features.account;

type Props = {
  isLoading?: boolean;
  data: WorkspaceUserInfo | undefined;
} & Pick<UseDisclosureReturn, "isOpen" | "onClose" | "onOpen">;

const ProfileMenuContent = ({ data, isLoading, isOpen, onClose }: Props) => {
  const router = useRouter();
  const navs = useProfileSideBarNavs();

  const [tabId, setTabId] = useState(
    getActiveTabByPathname(router.pathname)?.id,
  );

  useEffect(() => {
    const newTabId = getActiveTabByPathname(router.pathname)?.id;
    if (newTabId !== tabId) {
      setTabId(newTabId);
    }
  }, [router.pathname]);

  if (!feature.isEnabled) {
    return null;
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="stretch"
      width="100%"
      overflow="hidden"
      gap={4}
    >
      <ProfileAvatar
        gap={6}
        data={data}
        paddingX={0}
        avatarProps={{
          wrapperProps: { boxSize: "4.5rem", display: "flex", flexShrink: 0 },
        }}
        flexDirection="column"
        nameProps={{ textStyle: "1125500", color: "neutral.light.8" }}
        emailProps={{ textStyle: "875", color: "neutral.light.7" }}
        sx={{
          "& .profile-sidebar-header_text": {
            alignItems: "center",
          },
          "& .profile-sidebar-header_container": {
            flexDirection: "column",
            alignItems: "center",
            gap: 6,
          },
        }}
        isOpen={isOpen}
        onClose={onClose}
        onClickName={() => {
          router.push({ pathname: "/auth/profile" });
        }}
        hasToggle={false}
      ></ProfileAvatar>

      <VStack alignItems="stretch" gap={0}>
        <ProfileDivider></ProfileDivider>
        {navs.map((section) =>
          section.items?.map((item) => {
            return (
              <ProfileNavButton
                key={item.id}
                title={item.title}
                icon={item.icon}
                isDisabled={item.isDisabled}
                height="3rem"
                padding={3}
                gap={3}
                variant="unstyled"
                color="neutral.light.7"
                borderRadius="0.5rem"
                display="flex"
                alignItems="center"
                isLoading={isLoading}
                isActive={
                  item.id === "account" && tabId === "default"
                    ? true
                    : tabId === item.id
                }
                onClick={() => {
                  setTabId(tabId);
                  onClose();
                  router.push({ pathname: item?.href as any });
                }}
              ></ProfileNavButton>
            );
          }),
        )}
        <ProfileDivider></ProfileDivider>
        <ProfileSignOutButton></ProfileSignOutButton>
      </VStack>
    </Flex>
  );
};

export default ProfileMenuContent;
