export interface ExpiredItemData {
  value: any;
  expires: number;
}

export class StorageProvider {
  /**
   * @notice Constructor to initialize StorageProvider with the prefix and localStorage
   * @param PREFIX
   * @param localStorage
   */
  constructor(
    private PREFIX = "SEITRACE_",
    private localStorage: Storage,
  ) {}

  /**
   * @param key
   * @param value
   * @description
   * The function to set the value with an associated key
   */
  public setItem(key: string, value: string): void {
    if (!this.localStorage) return;
    this.localStorage.setItem(`${this.PREFIX}_${key}`, value);
  }

  /**
   * @param key
   * @returns value
   * @description
   * The function to get value with an associated key
   */
  public getItem(key: string): string | null {
    if (!this.localStorage) return null;
    return this.localStorage.getItem(`${this.PREFIX}_${key}`);
  }

  /**
   * @param key
   * @param value
   * @param ttl
   * @description
   * The function to set the value with an associated key and time to live
   */
  public setItemExpires(key: string, value: string, ttl: number) {
    if (!this.localStorage) return;
    const expires = Date.now() + ttl;
    this.localStorage.setItem(
      `${this.PREFIX}_${key}`,
      JSON.stringify({ value, expires } as ExpiredItemData),
    );
  }

  /**
   * @param key
   * @returns
   * @description
   * The function to get value with an associated key and check if it is expired
   */
  public getItemExpires(key: string) {
    try {
      if (!this.localStorage) return null;

      const data = this.getItemAsJson<ExpiredItemData>(key);

      if (!data) return null;

      if (Date.now() > data?.expires) {
        this.removeItem(key);
        return null;
      }
      return data.value;
    } catch {
      return null;
    }
  }

  /**
   * @param key
   * @returns
   * @description
   * The function to remove value with an associated key
   */
  public removeItem(key: string): void {
    if (!this.localStorage) return;
    return this.localStorage.removeItem(`${this.PREFIX}_${key}`);
  }

  /**
   * @notice The function to get item as json format
   * @param key
   */
  public getItemAsJson<T>(key: string): T | null {
    const value = this.getItem(key);
    if (!value) return null;
    return JSON.parse(value) as T;
  }

  /**
   * @notice Purge all local storage
   */
  public static purgeAll() {
    localStorage.clear();
  }
}
