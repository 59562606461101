export type IconSize = "md" | "lg";

export function getIconProps(size: IconSize | string = "md") {
  switch (size) {
    case "md": {
      return {
        boxSize: "20px", // for tables, lists and regular content
      };
    }
    case "lg": {
      return {
        boxSize: "30px", // for headings
      };
    }
    default: {
      return {
        boxSize: size as any,
      };
    }
  }
}
