import * as Sentry from "@sentry/react";
import axios, { AxiosRequestConfig } from "axios";
import React from "react";

import isBodyAllowed from "lib/api/isBodyAllowed";
import type { ResourceError, ResourcePath } from "lib/api/resources";

export interface Params {
  method?: AxiosRequestConfig["method"]; // Adjusted to string to align with axios method types
  headers?: AxiosRequestConfig["headers"];
  signal?: AbortSignal;
  body?: Record<string, unknown> | FormData;
  credentials?: RequestCredentials;
}

interface Meta {
  resource?: ResourcePath;
  omitSentryErrorLog?: boolean;
}

export default function useFetch() {
  return React.useCallback(
    async <Success, Error>(
      path: string,
      params?: Params,
      meta?: Meta,
      configs?: AxiosRequestConfig,
    ): Promise<Success | ResourceError<Error>> => {
      const _body = params?.body;
      const isFormData = _body instanceof FormData;
      const withBody = isBodyAllowed(params?.method);

      const data: FormData | Record<string, unknown> | undefined = (() => {
        if (!withBody) {
          return;
        }

        if (isFormData) {
          return _body;
        }

        return _body as Record<string, unknown>;
      })();

      return await axios({
        ...configs,
        url: path,
        method: params?.method,
        headers: {
          ...(withBody && !isFormData
            ? { "Content-type": "application/json" }
            : undefined),
          ...params?.headers,
        },
        data,
        signal: params?.signal,
      })
        .then((success) => success.data as Success)
        .catch((error) => {
          const status = error.response?.status;
          const statusText = error.response?.statusText;

          const err = {
            status,
            statusText,
          };

          if (!meta?.omitSentryErrorLog) {
            Sentry.captureException(new Error("Client fetch failed"), {
              tags: {
                source: "fetch",
                "source.resource": meta?.resource,
                "status.code": status,
                "status.text": statusText,
              },
            });
          }

          if (error.response?.data) {
            return Promise.reject({
              payload: error.response.data as Error,
              status,
              statusText,
            });
          } else {
            return Promise.reject(err);
          }
        });
    },
    [],
  );
}
