import { Box, Button, Center, Image, Text } from "@chakra-ui/react";

import { route } from "nextjs-routes";

import getErrorCauseStatusCode from "lib/errors/getErrorCauseStatusCode";
import getErrorObjStatusCode from "lib/errors/getErrorObjStatusCode";
import getResourceErrorPayload from "lib/errors/getResourceErrorPayload";

import LinkInternal from "../LinkInternal";
import AppErrorIcon from "./AppErrorIcon";
import AppErrorTitle from "./AppErrorTitle";
import AppErrorBlockConsensus from "./custom/AppErrorBlockConsensus";
import AppErrorInvalidTxHash from "./custom/AppErrorInvalidTxHash";
import AppErrorTooManyRequests from "./custom/AppErrorTooManyRequests";

interface Props {
  className?: string;
  error: Error | undefined;
}

const ERROR_TEXTS: Record<string, { title: string; text: string }> = {
  "404": {
    title: "Page not found",
    text: "Sorry! We encountered an unexpected error. Please try back again shortly.",
  },
  "405": {
    title: "Address not found",
    text: "Our explore cannot index this address!",
  },
  "422": {
    title: "Request cannot be processed",
    text: "Your request contained an error, perhaps a mistyped tx/block/address hash. Try again, and check the developer tools console for more info.",
  },
  "500": {
    title: "Oops! Something went wrong",
    text: "An unexpected error has occurred. Try reloading the page, or come back soon and try again.",
  },
};

const AppError = ({ error }: Props) => {
  const content = (() => {
    const resourceErrorPayload = getResourceErrorPayload(error);
    const messageInPayload =
      resourceErrorPayload &&
      typeof resourceErrorPayload === "object" &&
      "message" in resourceErrorPayload &&
      typeof resourceErrorPayload.message === "string"
        ? resourceErrorPayload.message
        : undefined;

    const isInvalidTxHash = error?.message?.includes("Invalid tx hash");
    const isBlockConsensus = messageInPayload?.includes("Block lost consensus");

    if (isInvalidTxHash) {
      return <AppErrorInvalidTxHash />;
    }

    if (isBlockConsensus) {
      const hash =
        resourceErrorPayload &&
        typeof resourceErrorPayload === "object" &&
        "hash" in resourceErrorPayload &&
        typeof resourceErrorPayload.hash === "string"
          ? resourceErrorPayload.hash
          : undefined;
      return <AppErrorBlockConsensus hash={hash} />;
    }

    const statusCode =
      getErrorCauseStatusCode(error) || getErrorObjStatusCode(error);

    switch (statusCode) {
      case 429: {
        return <AppErrorTooManyRequests />;
      }

      default: {
        const { title, text } =
          ERROR_TEXTS[String(statusCode)] ?? ERROR_TEXTS[500];

        return (
          <Box
            position="absolute"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
          >
            <AppErrorIcon statusCode={statusCode} />
            <Center
              flexDirection="column"
              position="absolute"
              left="50%"
              top="50%"
              width="100vw"
              paddingX={4}
              transform="translate(-50%, -50%)"
              textAlign="center"
              // whiteSpace="nowrap"
            >
              <AppErrorTitle title={title} />
              <Text mt={3} textStyle="1" color="neutral.light.8">
                {text}
              </Text>
              <Button
                as={LinkInternal}
                mt="2.5rem"
                minWidth="9.1875rem"
                variant="solid"
                href={route({ pathname: "/" })}
                textStyle="1"
              >
                Back home
              </Button>
            </Center>
          </Box>
        );
      }
    }
  })();

  return (
    <Box
      width="full"
      height={{
        base: "calc(100dvh - 61px - 1px - 52px - 31.3125rem)",
        lg: "calc(100dvh - 61px - 1px - 52px - 16.8125rem)",
      }}
      minHeight="20rem"
      overflow="hidden"
      position="relative"
    >
      <Image
        src="/icons/error-background.svg"
        minWidth="1600px"
        minHeight="1623px"
        width="full"
        height="full"
        color="neutral.light.3"
        position="absolute"
        objectFit="cover"
        left="50%"
        top="50%"
        zIndex={0}
        transform="translate(-50%, -50%)"
      ></Image>
      {content}
    </Box>
  );
};

export default AppError;
