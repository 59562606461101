import {
  Center,
  Divider,
  Flex,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react";
import type { UseQueryResult } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Element, Events, scroller } from "react-scroll";

import type { SearchResultItem } from "types/api/search";

import type { ResourceError } from "lib/api/resources";

import useMarketplaceApps from "ui/marketplace/useMarketplaceApps";
import type { ApiCategory, ItemsCategoriesMap } from "ui/shared/search/utils";
import { getItemCategory, searchCategories } from "ui/shared/search/utils";

import { debounce } from "lodash";
import Empty from "ui/shared/Empty";
import Loading from "ui/shared/Loading";
import SearchBarSuggestApp from "./SearchBarSuggestApp";
import SearchBarSuggestItem from "./SearchBarSuggestItem";

interface Props {
  query: UseQueryResult<Array<SearchResultItem>, ResourceError<unknown>>;
  searchTerm: string;
  onItemClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  containerId: string;
}

const SearchBarSuggest = ({
  query,
  searchTerm,
  onItemClick,
  containerId,
}: Props) => {
  const marketplaceApps = useMarketplaceApps(searchTerm);

  const categoriesRefs = React.useRef<Array<HTMLParagraphElement | null>>([]);
  const tabsRef = React.useRef<HTMLDivElement | null>(null);
  const [tabIndex, setTabIndex] = React.useState(0);

  const itemsGroups = React.useMemo(() => {
    if (!query.data && !marketplaceApps.displayedApps) {
      return {};
    }
    const map: Partial<ItemsCategoriesMap> = {};
    query.data?.forEach((item) => {
      const cat = getItemCategory(item) as ApiCategory;
      if (cat) {
        if (cat in map) {
          map[cat]?.push(item);
        } else {
          map[cat] = [item];
        }
      }
    });
    if (marketplaceApps.displayedApps.length) {
      map.app = marketplaceApps.displayedApps;
    }
    return map;
  }, [query.data, marketplaceApps.displayedApps]);

  const resultCategories = searchCategories.filter(
    (cat) => itemsGroups[cat.id],
  );

  const handleScroll = React.useCallback(
    (elements: globalThis.Element[]) => {
      const container = document.getElementById(containerId);
      if (!container || !query.data?.length) {
        return;
      }
      const containerTop = container.getBoundingClientRect().top;
      const containerBottom = container.getBoundingClientRect().bottom;
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const elementTop = element.getBoundingClientRect().top;

        if (containerTop <= elementTop && elementTop <= containerBottom) {
          setTabIndex(i);
          return;
        }
      }
    },
    [containerId, query.data],
  );

  useEffect(() => {
    // Events.scrollEvent.register("begin", function () {
    //   console.log("begin");
    // });

    Events.scrollEvent.register("end", (id, element) => {
      if (!element) return;
      const container = document.getElementById(containerId);
      if (!container) return;

      const containerRect = container?.getBoundingClientRect();

      const elementRect = element.getBoundingClientRect();
      const elementViewPort = elementRect.top - containerRect.top;
      if (0 <= elementViewPort && elementViewPort <= containerRect.height) {
        setTabIndex(Number(id.replace("cat_", "")));
      }
    });
  }, []);

  React.useEffect(() => {
    if (!resultCategories.length) return;
    const container = document.getElementById(containerId);

    const listElementRaw = container?.querySelectorAll(
      resultCategories.map((cat) => `[id='${cat.id}']`).join(", "),
    );

    const listElement: globalThis.Element[] = [];
    listElementRaw?.forEach((element) => listElement.push(element));
    const throttledHandleScroll = debounce(
      () => handleScroll(listElement),
      200,
    );
    if (container) {
      container.addEventListener("scroll", throttledHandleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", throttledHandleScroll);
      }
    };
  }, [containerId, handleScroll, resultCategories]);

  React.useEffect(() => {
    categoriesRefs.current = Array(Object.keys(itemsGroups).length)
      .fill("")
      .map((_, i) => categoriesRefs.current[i] || React.createRef()) as any;
  }, [itemsGroups]);

  const scrollToCategory = React.useCallback(
    (index: number) => {
      scroller.scrollTo(`cat_${index}`, {
        duration: 250,
        smooth: true,
        offset: -(tabsRef.current?.clientHeight || 0),
        containerId: containerId,
      });
    },
    [containerId],
  );

  const content = (() => {
    if (query.isPending || marketplaceApps.isPlaceholderData) {
      return (
        <Center flex={1} flexDirection="column" gap={2} cursor="default">
          <Loading />
          <Text
            color="neutral.light.6"
            lineHeight="1.25rem"
            fontSize="0.875rem"
            fontWeight={400}
          >
            Loading
          </Text>
        </Center>
      );
    }

    if (query.isError) {
      return (
        <Text py={2} px={4}>
          Something went wrong. Try refreshing the page or come back later.
        </Text>
      );
    }

    if (!query.data || query.data.length === 0) {
      return (
        <Center
          flex={1}
          width="full"
          height="full"
          flexDirection="column"
          gap={3}
        >
          <Empty
            boxSize="6.25rem"
            text={
              <Text textAlign="center" variant="light7">
                Keyword not found.
                <br />
                Please check your keyword
              </Text>
            }
          ></Empty>
        </Center>
      );
    }

    return (
      <>
        {resultCategories.length > 1 && (
          <Flex py={2} px={4} width="100%" ref={tabsRef} gap={2}>
            <Tabs variant="solid" size="sm" index={tabIndex}>
              <TabList columnGap={3} gap={2} flexWrap="wrap">
                {resultCategories.map((cat, index) => (
                  <Tab
                    key={cat.id}
                    onClick={() => {
                      scrollToCategory(index);
                    }}
                    padding="0.38rem 1rem"
                  >
                    {cat.title}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          </Flex>
        )}
        <Flex
          flexDirection="column"
          width="full"
          overflowY="auto"
          maxHeight={{ lg: "30rem" }}
          id={containerId}
          as={Element}
        >
          {resultCategories.map((cat, indx) => {
            return (
              <>
                <Divider variant="horizontal" />
                <Element
                  style={{ display: "flex", flexDirection: "column" }}
                  name={`cat_${indx}`}
                  onClick={() => {
                    document.documentElement.style.overflowY = "scroll";
                  }}
                  key={cat.id}
                  id={cat.id}
                >
                  <Text
                    fontSize="0.8125rem"
                    lineHeight="1rem"
                    fontWeight={400}
                    color="neutral.light.5"
                    mt={4}
                    mx={4}
                    mb={1}
                    ref={(el) => {
                      categoriesRefs.current[indx] = el;
                    }}
                  >
                    {cat.title}
                  </Text>
                  {cat.id !== "app" &&
                    itemsGroups[cat.id]?.map((item, index) => (
                      <SearchBarSuggestItem
                        key={index}
                        data={item}
                        searchTerm={searchTerm}
                        onClick={onItemClick}
                      />
                    ))}
                  {cat.id === "app" &&
                    itemsGroups[cat.id]?.map((item, index) => (
                      <SearchBarSuggestApp
                        key={index}
                        data={item}
                        searchTerm={searchTerm}
                        onClick={onItemClick}
                      />
                    ))}
                </Element>
              </>
            );
          })}
        </Flex>
      </>
    );
  })();

  return content;
};

export default SearchBarSuggest;
