import type { ChakraProps } from "@chakra-ui/react";
import {
  Box,
  Link,
  Skeleton,
  chakra,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

import IconSvg from "ui/shared/IconSvg";

interface Props {
  href: string;
  className?: string;
  children: React.ReactNode;
  isLoading?: boolean;
  variant?: "subtle";
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  noIcon?: boolean;
}

const LinkExternal = ({
  href,
  variant,
  onClick,
  children,
  className,
  isLoading,
  noIcon,
}: Props) => {
  const subtleLinkBg = useColorModeValue("gray.100", "gray.700");

  const styleProps: ChakraProps = (() => {
    const commonProps = {
      display: "inline-block",
      alignItems: "center",
    };

    switch (variant) {
      case "subtle": {
        return {
          ...commonProps,
          px: "10px",
          py: "6px",
          bgColor: subtleLinkBg,
          borderRadius: "base",
        };
      }

      default: {
        return commonProps;
      }
    }
  })();

  if (isLoading) {
    if (variant === "subtle") {
      return (
        <Skeleton className={className} {...styleProps} bgColor="inherit">
          {children}
          <Box boxSize={4} display="inline-block" />
        </Skeleton>
      );
    }

    return (
      <Box className={className} {...styleProps}>
        {children}
        <Skeleton boxSize={4} verticalAlign="middle" display="inline-block" />
      </Box>
    );
  }

  return (
    <Link
      className={className}
      {...styleProps}
      display="flex"
      gap={2}
      target="_blank"
      href={href}
      onClick={onClick}
    >
      {children}
      {!noIcon && (
        <IconSvg
          name="arrows/north-east"
          boxSize={4}
          verticalAlign="middle"
          color="neutral.light.6"
          flexShrink={0}
        />
      )}
    </Link>
  );
};

export default chakra(LinkExternal);
