import React, { createContext, useContext, useEffect } from "react";

import isBrowser from "lib/isBrowser";
import { onChainChange } from "lib/redux/reducers/chain";
import { onPathnameChange } from "lib/redux/reducers/navigation";
import { useAppDispatch } from "lib/redux/store";
import { useRouter } from "next/router";
import type { Props as PageProps } from "nextjs/getServerSideProps";

type Props = {
  children: React.ReactNode;
  pageProps: PageProps;
};

const AppContext = createContext<PageProps>({
  cookies: "",
  referrer: "",
  id: "",
  height_or_hash: "",
  hash: "",
  number: "",
  q: "",
  channel_id: "",
  counterparty_channel_id: "",
});

export function AppContextProvider({ children, pageProps }: Props) {
  const dispatch = useAppDispatch();
  const router = useRouter();

  useEffect(() => {
    dispatch(onPathnameChange());
  }, [
    isBrowser() ? window.location.pathname : router.pathname,
    router.query.tab,
  ]);

  useEffect(() => {
    dispatch(
      onChainChange({ chainKey: router.query.chain as string | undefined }),
    );
  }, [router.query.chain]);

  return (
    <AppContext.Provider value={pageProps}>{children}</AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
