import { BaseTokenType, TokenInfo } from "types/api/token";

export const getAssociationTokenData = (
  token: TokenInfo | undefined,
): {
  self: "Pointer" | "Original";
  associationType: "Pointer" | "Original";
  associationAddress: string;
  associationTokenType: BaseTokenType;
} | null => {
  if (!token) return null;
  if (!token.association) return null;
  if (
    token.association.type === "EOA" ||
    token.association.type === "TRANSACTION"
  ) {
    return null;
  }

  const associationType = token.association.type
    .replace("CREATE_", "")
    .replace("_POINTER", "");

  const tokenType = token.type as BaseTokenType;
  const evmTypes = ["ERC721", "ERC1155", "ERC20"];
  const cosmosTypes = ["CW721", "CW20"];
  const associationTokenTypeMappingOppositeTokenType: Record<
    BaseTokenType,
    BaseTokenType
  > = {
    "CW-20": "ERC-20",
    "CW-721": "ERC-721",
    "ERC-20": "CW-20",
    "ERC-721": "CW-721",
    "ERC-1155": "CW-721",
    coin_transfer: "ERC-20",
  };

  if (evmTypes.find((t) => t === associationType)) {
    if (evmTypes.find((t) => t === token.type.replace("-", ""))) {
      return {
        self: "Pointer",
        associationType: "Original",
        associationAddress: token.association.sei_hash,
        associationTokenType:
          associationTokenTypeMappingOppositeTokenType[tokenType],
      };
    } else {
      return {
        self: "Original",
        associationType: "Pointer",
        associationAddress: token.association.evm_hash,
        associationTokenType:
          associationTokenTypeMappingOppositeTokenType[tokenType],
      };
    }
  }

  if (cosmosTypes.find((t) => t === token.type.replace("-", ""))) {
    return {
      self: "Pointer",
      associationType: "Original",
      associationAddress: token.association.evm_hash,
      associationTokenType:
        associationTokenTypeMappingOppositeTokenType[tokenType],
    };
  } else {
    return {
      self: "Original",
      associationType: "Pointer",
      associationAddress: token.association.sei_hash,
      associationTokenType:
        associationTokenTypeMappingOppositeTokenType[tokenType],
    };
  }
};
