import { Grid, GridItem, useColorModeValue } from "@chakra-ui/react";
import React from "react";

import type { GasPrices } from "types/api/stats";

const GasInfoTooltipContent = ({ gasPrices }: { gasPrices: GasPrices }) => {
  const nameStyleProps = {
    color: useColorModeValue("blue.100", "primary.300"),
  };

  return (
    <Grid
      templateColumns="repeat(2, max-content)"
      rowGap={2}
      columnGap={4}
      padding={4}
      fontSize="xs"
    >
      <GridItem {...nameStyleProps}>Slow</GridItem>
      <GridItem>
        {gasPrices.slow !== null ? `${gasPrices.slow} gusei` : "N/A"}
      </GridItem>
      <GridItem {...nameStyleProps}>Average</GridItem>
      <GridItem>
        {gasPrices.average !== null ? `${gasPrices.average} gusei` : "N/A"}
      </GridItem>
      <GridItem {...nameStyleProps}>Fast</GridItem>
      <GridItem>
        {gasPrices.fast !== null ? `${gasPrices.fast} gusei` : "N/A"}
      </GridItem>
    </Grid>
  );
};

export default React.memo(GasInfoTooltipContent);
