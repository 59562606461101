import { Button, Center, Flex, Text, useDisclosure } from "@chakra-ui/react";
import useIsMobile from "lib/hooks/useIsMobile";
import * as mixpanel from "lib/mixpanel/index";
import {
  getRecentSearchKeywords,
  saveToRecentKeywords,
} from "lib/recentSearchKeywords";
import _ from "lodash";
import router from "next/router";
import { route } from "nextjs-routes";
import React, { useEffect } from "react";
import IconSvg from "ui/shared/IconSvg";
import {
  PopoverModal,
  PopoverModalContent,
  PopoverModalTrigger,
} from "ui/shared/PopoverModal";
import SearchBarRecentKeywords from "./SearchBarRecentKeywords";
import SearchBarSuggest from "./SearchBarSuggest/SearchBarSuggest";
import SearchInput, { SearchInputProps } from "./SearchInput";
import useQuickSearchQuery from "./useQuickSearchQuery";

type Props = {
  isFullscreen?: boolean;
  searchInputProps?: SearchInputProps;
};

const SCROLL_CONTAINER_ID = "search_bar_popover_content";

const SearchBarTop = ({ searchInputProps, isFullscreen }: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const recentSearchKeywords = getRecentSearchKeywords();

  const {
    searchTerm,
    debouncedSearchTerm,
    handleSearchTermChange,
    query,
    pathname,
  } = useQuickSearchQuery();

  const handleSubmit = React.useCallback(() => {
    if (searchTerm) {
      const url = route({
        pathname: "/search-results",
        query: { q: searchTerm },
      });
      mixpanel.logEvent(mixpanel.EventTypes.SEARCH_QUERY, {
        "Search query": searchTerm,
        "Source page type": mixpanel.getPageType(pathname as any),
        "Result URL": url,
      });
      saveToRecentKeywords(searchTerm);
      router.push(
        { pathname: "/search-results", query: { q: searchTerm } },
        undefined,
        { shallow: true, scroll: false },
      );
    }
  }, [searchTerm, pathname, router]);

  const handleItemClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      mixpanel.logEvent(mixpanel.EventTypes.SEARCH_QUERY, {
        "Search query": searchTerm,
        "Source page type": mixpanel.getPageType(pathname as any),
        "Result URL": event.currentTarget.href,
      });
      saveToRecentKeywords(searchTerm);
      onClose();
    },
    [pathname, searchTerm, onClose],
  );

  const isMobile = useIsMobile();
  useEffect(() => {
    if (isMobile && isOpen) {
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "auto";
    }
    return () => {
      document.documentElement.style.overflowY = "auto";
    };
  }, [isMobile, isOpen]);

  return (
    <>
      {!(isFullscreen === false) && (
        <Button
          order={2}
          variant="primary"
          boxSize={9}
          display={{ base: "flex", lg: "none" }}
          alignItems="center"
          justifyContent="center"
          padding={0}
          onClick={() => {
            document.documentElement.style.overflowY = "hidden";
            onOpen();
          }}
        >
          <IconSvg name="search" boxSize={5} color="neutral.light.6" />
        </Button>
      )}

      <PopoverModal
        id="searchbar"
        zIndex={isFullscreen === false ? 1 : 1001}
        isFullscreen={isFullscreen}
        isOpen={isOpen}
        onClose={onClose}
        backgroundColor={isFullscreen === false ? undefined : "neutral.light.1"}
      >
        <PopoverModalTrigger
          as={Flex}
          alignItems="center"
          gap={3}
          marginTop={{
            base: isFullscreen === false ? "unset" : 2,
            lg: "unset",
          }}
          marginX={{ base: isFullscreen === false ? "unset" : 4, lg: "unset" }}
        >
          {!(isFullscreen === false) && (
            <Button
              variant="unstyled"
              boxSize={6}
              display={{ base: "flex", lg: "none" }}
              justifyContent="center"
              alignItems="center"
              onClick={() => {
                onClose?.();
                document.documentElement.style.overflowY = null as any;
              }}
            >
              <IconSvg
                name="arrows/east-left"
                color="neutral.light.6"
                boxSize="1.125rem"
              ></IconSvg>
            </Button>
          )}
          <SearchInput
            flex={1}
            size="xs"
            onChange={(e) => handleSearchTermChange(e.target.value)}
            value={searchTerm}
            onSubmit={handleSubmit}
            onClick={() => onOpen()}
            {...{
              ...searchInputProps,
              groupProps: _.merge(
                {
                  order: { lg: 3 },
                  width: {
                    base: "full",
                    lg: isFullscreen === false ? "full" : "25rem",
                  },
                  backgroundColor: "neutral.light.1",
                },
                searchInputProps?.groupProps,
              ),
            }}
          />
        </PopoverModalTrigger>
        <PopoverModalContent>
          <Flex
            flexDirection="column"
            width="full"
            alignItems="center"
            paddingY={2}
            // maxHeight={isFullscreen === false ? "30rem" : "full"}
            minH={{
              base: isFullscreen === false ? "15rem" : undefined,
              lg: "15rem",
            }}
            maxHeight={{
              base: isFullscreen === false ? "90dvh" : "full",
              lg: isFullscreen === false ? "25rem" : "90dvh",
            }}
          >
            {searchTerm.trim().length === 0 &&
              recentSearchKeywords.length > 0 && (
                <SearchBarRecentKeywords
                  onClick={handleSearchTermChange}
                  onClear={onClose}
                />
              )}
            {!searchTerm.trim().length && !recentSearchKeywords.length && (
              <Center flex={1}>
                <Text variant="light5">Enter keyword for searching</Text>
              </Center>
            )}
            {searchTerm.trim().length > 0 && (
              <SearchBarSuggest
                query={query}
                searchTerm={debouncedSearchTerm}
                onItemClick={handleItemClick}
                containerId={SCROLL_CONTAINER_ID}
              />
            )}
          </Flex>
        </PopoverModalContent>
      </PopoverModal>
    </>
  );
};

export default SearchBarTop;
