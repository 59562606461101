import { Link, LinkProps, chakra, shouldForwardProp } from "@chakra-ui/react";
import NextLink from "next/link";
import React from "react";

import type { NavItem } from "types/client/navigation-items";

import { route } from "nextjs-routes";

import { isInternalItem } from "lib/hooks/useNavItems";
import Tag from "ui/shared/chakra/Tag";

type Props = {
  item: NavItem;
  color?: string;
  isActive?: boolean;
  px?: string | number;
  onClick?: () => void;
  isCollapsed?: boolean;
} & Partial<LinkProps>;

export const NavLink = ({ item, color, onClick, ...props }: Props) => {
  // const isExpanded = isCollapsed === false;
  const isInternalLink = isInternalItem(item);
  const href = isInternalLink ? route(item.nextRoute) : item.url;

  const content = (
    <Link
      href={href}
      target={isInternalLink ? "_self" : "_blank"}
      display="flex"
      borderRadius={{ base: "md", lg: "unset" }}
      aria-label={`${item.text} link`}
      whiteSpace="nowrap"
      onClick={onClick}
      width="full"
      height="full"
      role="group"
      paddingY={{ base: 3, lg: "unset" }}
      alignItems="center"
      color={color || "neutral.light.7"}
      _hover={{ color: "primary.light.4" }}
      fontWeight={400}
      lineHeight="1.5rem"
      fontSize="1rem"
      gap={2}
      {...props}
    >
      <span>{item.text}</span>
      {item.isEvmOnly && (
        <Tag
          paddingX={1}
          paddingY={0}
          height="0.875rem"
          minHeight="unset"
          display="flex"
          minWidth="unset"
          tagProps={{
            borderRadius: "0.125rem",
            minHeight: "unset",
            textStyle: "625",
            height: "0.875rem",
            width: "unset",
            display: "inline-flex",
            textAlign: "center",
            verticalAlign: "middle",
            variant: "outline",
            colorScheme: "red",
            minWidth: "unset",
            fontSize: "0.625rem",
          }}
        >
          EVM only
        </Tag>
      )}
      {item.isNewAPI && (
        <Tag
          paddingX={1}
          paddingY={0}
          height="0.875rem"
          minHeight="unset"
          display="flex"
          minWidth="unset"
          tagProps={{
            borderRadius: "0.125rem",
            minHeight: "unset",
            textStyle: "625",
            height: "0.875rem",
            width: "unset",
            display: "inline-flex",
            textAlign: "center",
            verticalAlign: "middle",
            variant: "outline",
            colorScheme: "orange",
            minWidth: "unset",
            fontSize: "0.625rem",
          }}
        >
          New (API)
        </Tag>
      )}
    </Link>
  );

  return isInternalLink ? (
    <NextLink
      style={{ width: "100%", height: "100%" }}
      href={item.nextRoute as any}
      passHref
      legacyBehavior
    >
      {content}
    </NextLink>
  ) : (
    content
  );
};

const NavLinkChakra = chakra(NavLink, {
  shouldForwardProp: (prop) => {
    const isChakraProp = !shouldForwardProp(prop);

    if (isChakraProp && prop !== "px") {
      return false;
    }

    return true;
  },
});

export default React.memo(NavLinkChakra);
