import { Box, Flex, chakra } from "@chakra-ui/react";
import React from "react";

interface Props {
  className?: string;
  children: React.ReactNode;
}

const MainColumn = ({ children, className }: Props) => {
  return (
    <Flex
      className={className}
      flexDir="column"
      flexGrow={1}
      // paddingTop={{ base: `${32 + 60}px`, lg: 9 }} // 32px is top padding of content area, 60px is search bar height
      // paddingBottom={10}
      width="100%"
    >
      <Box
        mx="auto"
        w={{ base: "100%", xl: "1024px" }}
        px={{ base: "15px", xl: "0px" }}
      >
        {children}
      </Box>
    </Flex>
  );
};

export default React.memo(chakra(MainColumn));
