import { ButtonProps, Button } from "@chakra-ui/react";
import { IconName } from "public/icons/name";
import React from "react";
import IconSvg from "ui/shared/IconSvg";

type Props = { src?: string; name?: IconName } & Partial<ButtonProps>;

const WalletItem = ({ name, src, ...props }: Props) => {
  return (
    <Button
      display="flex"
      justifyContent="center"
      alignItems="center"
      variant="unstyled"
      backgroundColor="black"
      borderRadius="0.375rem"
      borderWidth="0.5px"
      borderColor="neutral.light.7"
      overflow="hidden"
      boxSize={7}
      padding="0.16rem"
      {...props}
    >
      {name && !src && <IconSvg boxSize="full" name={name}></IconSvg>}
      {!name && src && <IconSvg boxSize="full" src={src}></IconSvg>}
    </Button>
  );
};

export default WalletItem;
