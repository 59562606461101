import { useColorModeValue } from "@chakra-ui/react";

export default function useColors() {
  return {
    text: {
      default: useColorModeValue("#3D3D3D", "gray.400"),
      active: useColorModeValue("#BD0F36", "gray.50"),
      hover: "#FFEEEE",
    },
    bg: {
      default: "transparent",
      active: useColorModeValue("#FFEEEE", "transapent"),
    },
    border: {
      default: "divider",
      active: useColorModeValue("primary.light.3", "gray.800"),
    },
  };
}
