import { Box, BoxProps, Image } from "@chakra-ui/react";
import React from "react";

import type { HeightToken, WidthToken } from "nextjs/types";

import { route } from "nextjs-routes";

import IconSvg from "ui/shared/IconSvg";
import Link from "next/link";
type Props = {
  isCollapsed?: boolean;
  width?: WidthToken;
  height?: HeightToken;
  onClick?: (event: React.SyntheticEvent) => void;
} & Partial<BoxProps>;

const NetworkLogo = ({ boxSize, ...props }: Props) => {
  const { onClick } = props;

  return (
    <Box
      as={Link}
      href={route({ pathname: "/" })}
      display="inline-flex"
      overflow="hidden"
      onClick={onClick}
      flexShrink={0}
      aria-label="Link to main page"
      alignItems="center"
      gap={2}
      {...props}
    >
      <Image
        src="/icons/logo/logo.svg"
        boxSize={{ base: 8, lg: 7, xl: 8 }}
        flexShrink={0}
      ></Image>
      <IconSvg
        flexShrink={0}
        name="seitrace"
        boxSize={boxSize}
        width={{ base: "7.59688rem", lg: "6.64725rem", xl: "7.59688rem" }}
        height={{ base: "1.08rem", lg: "0.945rem", xl: "1.08rem" }}
      ></IconSvg>
    </Box>
  );
};

export default React.memo(NetworkLogo);
