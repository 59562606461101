import { BoxProps, Flex, forwardRef, Portal } from "@chakra-ui/react";
import { useContext } from "react";
import { PopoverModalContext } from "./PopoverModalContext";

type Props = {
  children?: React.ReactNode;
  isPortal?: boolean;
} & Partial<BoxProps>;

const PopoverModalContent = (
  { children, isPortal, ...props }: Props,
  ref: any,
) => {
  const { open, isFullscreen } = useContext(PopoverModalContext);

  const localProps = {
    position: "absolute" as any,
    mt: 2,
    width: "25rem",
    maxWidth: "90vw",
    boxShadow: "lg",
    borderRadius: "0.5rem",
    border: "1px",
    borderColor: "neutral.light.3",
  };

  const content = (
    <Flex
      justifyContent={{ base: "flex-start", lg: "center" }}
      alignItems="center"
      flexDirection="column"
      ref={ref}
      visibility={open ? "visible" : "hidden"}
      transition="all 0.08s ease-in-out"
      top="100%"
      opacity={open ? "1" : "0"}
      flex={1}
      overflowY="auto"
      backgroundColor="neutral.light.1"
      zIndex={1000}
      position={{
        base: isFullscreen === false ? localProps.position : undefined,
        lg: localProps.position,
      }}
      mt={{
        base: isFullscreen === false ? localProps.mt : undefined,
        lg: localProps.mt,
      }}
      width={{
        base: isFullscreen === false ? "full" : undefined,
        lg: isFullscreen === false ? "full" : localProps.width,
      }}
      maxWidth={{
        // base: isFullscreen === false ? "full" : undefined,
        lg: isFullscreen === false ? undefined : localProps.maxWidth,
      }}
      boxShadow={{
        base: isFullscreen === false ? localProps.boxShadow : undefined,
        lg: localProps.boxShadow,
      }}
      borderRadius={{
        base: isFullscreen === false ? localProps.borderRadius : undefined,
        lg: localProps.borderRadius,
      }}
      border={{
        base: isFullscreen === false ? localProps.border : undefined,
        lg: localProps.border,
      }}
      borderColor={{
        base: isFullscreen === false ? localProps.borderColor : undefined,
        lg: localProps.borderColor,
      }}
      {...props}
    >
      {/* {onClose && <Flex>X</Flex>} */}
      {children}
    </Flex>
  );
  if (isPortal) {
    return <Portal>{content}</Portal>;
  }
  return content;
};

export default forwardRef(PopoverModalContent);
