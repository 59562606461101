import { Box } from "@chakra-ui/react";
import React from "react";

import type { IconName } from "ui/shared/IconSvg";
import IconSvg from "ui/shared/IconSvg";

const ICONS: Record<string, IconName> = {
  "404": "error-pages/404",
  "422": "error-pages/422",
  "429": "error-pages/429",
  "500": "error-pages/500",
};

interface Props {
  statusCode: number | undefined;
}

const AppErrorIcon = ({ statusCode }: Props) => {
  return (
    <Box
      minWidth={{ base: "100vw", lg: "45vw" }}
      paddingX={4}
      aspectRatio={2.6958}
    >
      <IconSvg
        name={ICONS[String(statusCode)] || ICONS["500"]}
        // height="30dvh"
        width="full"
        height="full"
        color="neutral.light.3"
        // backgroundColor="red"
      />
    </Box>
  );
};

export default AppErrorIcon;
