import type { UseQueryOptions } from "@tanstack/react-query";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { useEffect, useMemo, useRef, useState } from "react";
import type { ResourceError, ResourceName, ResourcePayload } from "./resources";
import type { Params as ApiFetchParams } from "./useApiFetch";
import useApiFetch from "./useApiFetch";

export interface Params<R extends ResourceName, E = unknown>
  extends ApiFetchParams<R> {
  delayedTime?: number;
  queryOptions?: Omit<
    UseQueryOptions<ResourcePayload<R>, ResourceError<E>, ResourcePayload<R>>,
    "queryKey" | "queryFn"
  >;
  onStart?: AnyFunction;
  onSuccess?: (success?: ResourcePayload<R>) => void;
  onError?: (error: ResourceError<E>) => void;
  onFinally?: AnyFunction;
}

export function getResourceKey<R extends ResourceName>(
  resource: R,
  { pathParams, queryParams }: Params<R> = {},
) {
  if (pathParams || queryParams) {
    return [resource, { ...pathParams, ...queryParams }];
  }

  return [resource];
}

export type UseApiQueryType<
  R extends ResourceName = any,
  E = unknown,
> = ReturnType<typeof useApiQuery<R, E>>;

export default function useApiQuery<R extends ResourceName, E = unknown>(
  resource: R,
  {
    queryOptions,
    pathParams,
    queryParams,
    fetchParams,
    configs,
    onFinally,
    onStart,
    onSuccess,
    onError,
  }: Params<R, E> = {},
) {
  const apiFetch = useApiFetch();

  //@ts-ignore
  return useQuery<ResourcePayload<R>, ResourceError<E>, ResourcePayload<R>>({
    //@ts-ignore
    queryKey: getResourceKey(resource, { pathParams, queryParams }),
    queryFn: async () => {
      onStart?.();
      // all errors and error typing is handled by react-query
      // so error response will never go to the data
      // that's why we are safe here to do type conversion "as Promise<ResourcePayload<R>>"
      return apiFetch(
        resource as never,
        {
          pathParams,
          queryParams,
          fetchParams,
          configs,
        } as never,
      )
        .then((success) => {
          onSuccess?.(success as never);
          return success as never;
        })
        .catch((error) => {
          onError?.(error as never);
          throw error;
        })
        .finally(onFinally) as never as Promise<ResourcePayload<R>>;
    },
    ...queryOptions,
  });
}

export async function requestApiFetch<R extends ResourceName, E = unknown>(
  resource: R,
  { pathParams, queryParams, fetchParams }: Params<R, E> = {},
) {
  const apiFetch = useApiFetch();

  return apiFetch(resource, {
    pathParams,
    queryParams,
    fetchParams,
  });
}

export function useRequest<R extends ResourceName, E = unknown>(
  resource: R,
  {
    pathParams,
    queryParams,
    fetchParams,
    disabled = false,
    queryOptions,
  }: Params<R, E> & { disabled?: boolean } = {},
  watchedData: any[] = [],
  delay = 2000,
): UseQueryResult<ResourcePayload<R>, ResourceError<E>> {
  const isDisabled = disabled || queryOptions?.enabled === false;
  const apiFetch = useApiFetch();
  const memonizedData = useRef<any[]>([]);
  const [queryResultData, setQueryResultData] = useState<
    UseQueryResult<ResourcePayload<R>, ResourceError<E>>
  >({
    data: queryOptions?.placeholderData || null,
    isPlaceholderData: true,
  } as any);

  const get = async () => {
    const queryResult = await apiFetch(resource, {
      pathParams,
      queryParams,
      fetchParams,
    }).catch((error) => console.log(error));

    setQueryResultData({
      data: queryResult as any,
      isPlaceholderData: false,
    } as any);
    return queryResult;
  };

  useEffect(() => {
    if (isDisabled) return;
    let handler: NodeJS.Timeout | null = null;
    if (JSON.stringify(watchedData) !== JSON.stringify(memonizedData.current)) {
      handler = setTimeout(() => {
        get();
        memonizedData.current = watchedData;
      }, delay);
    }
    return () => {
      handler && clearTimeout(handler);
    };
  }, [watchedData, disabled, queryOptions?.enabled]);

  return useMemo(
    () =>
      ({
        ...queryResultData,
        isPlaceholderData: isDisabled
          ? false
          : queryResultData.isPlaceholderData,
        refetch: get,
      }) as never,
    [queryResultData],
  );
}
