import { Heading } from "@chakra-ui/react";
import React from "react";

interface Props {
  title: string;
}

const AppErrorTitle = ({ title }: Props) => {
  return (
    <Heading
      textStyle="225"
      letterSpacing="-0.0675rem"
      color="neutral.light.8"
      fontFamily="body"
    >
      {title}
    </Heading>
  );
};

export default AppErrorTitle;
