import api from "./api";
import app from "./app";
import chain from "./chain";
import * as features from "./features";
import ipfs from "./ipfs";
import meta from "./meta";
import services from "./services";
import UI from "./ui";

const config = Object.freeze({
  app,
  chain,
  api,
  UI,
  features,
  services,
  meta,
  ipfs,
});

export * as utils from "./utils";

export default config;
