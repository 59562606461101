import { Flex, Text } from "@chakra-ui/react";
import React from "react";

import type { SearchResultTx } from "types/api/search";

import moment from "lib/date/moment";
import IconSvg from "ui/shared/IconSvg";

interface Props {
  data: SearchResultTx;

  searchTerm: string;
}

const SearchBarSuggestTx = ({ data }: Props) => {
  const date = moment(data.timestamp).format("llll");

  return (
    <Flex alignItems="center" gap={3} overflow="hidden">
      <IconSvg name="exchange" boxSize={4} color="secondary.04" />

      <Flex flexDirection="column" gap={1} flex={1} overflow="hidden">
        <Text width="full" isTruncated color="neutral.light.8" size="875">
          {data.tx_hash}
        </Text>
        <Text width="full" isTruncated color="neutral.light.6" size="8125">
          {date}
        </Text>
      </Flex>
    </Flex>
  );
};

export default React.memo(SearchBarSuggestTx);
